import { Link } from "react-router-dom";
import cx from "classnames";
import Icon from "components/Icon";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import DisplayValue from "components/DisplayValue";
import Modal from "components/Modal";
import Permission from "components/Form/AdminForm/Permission";

import useToggle from "hooks/useToggle";
import enhancer from "./AdminId.compose";
import { dateFormatterFromPattern, phoneNumberFormat } from "utils/formatter";
import styles from "./AdminId.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import { Formik } from "formik";
import authorize, { UnauthorizedAction } from "utils/authorize";

function Confirmation({ sendConfirmEmail = () => {} }) {
  const [showModal, , handleOpenModal, handleCloseModal] = useToggle();

  return (
    <>
      <button className={cx("secondary", styles.btn)} onClick={handleOpenModal}>
        <div>
          <Icon name="mail" className={styles.icon} />
          ส่งอีเมลยืนยันตัวตน
        </div>
      </button>
      <Modal
        isOpen={showModal}
        onSubmit={() => {
          sendConfirmEmail();
          handleCloseModal();
        }}
        onCancel={handleCloseModal}
        submitText={"ส่งอีเมล"}
        cancelText={"ไม่ส่งอีเมล"}
        contentLabel="Confirm email admin Modal"
      >
        <p>
          ต้องการส่งอีเมลยืนยันตัวตน
          <br />
          ให้กับพนักงานหรือไม่ ?
        </p>
      </Modal>
    </>
  );
}

function UpdateStatus({ sendUpdateEmail = () => {} }) {
  const [showModal, , handleOpenModal, handleCloseModal] = useToggle();

  return (
    <>
      <button className={cx("secondary", styles.btn)} onClick={handleOpenModal}>
        <div>
          <Icon name="update-status" className={styles.icon} />
          ส่งอีเมลอัปเดตสถานะ
        </div>
      </button>
      <Modal
        isOpen={showModal}
        onSubmit={() => {
          sendUpdateEmail();
          handleCloseModal();
        }}
        onCancel={handleCloseModal}
        submitText={"ส่งอีเมล"}
        cancelText={"ไม่ส่งอีเมล"}
        contentLabel="Update status email admin Modal"
      >
        <p>
          ต้องการส่งอีเมลแจ้งอัปเดตสถานะ
          <br />
          ให้กับพนักงานหรือไม่ ?
        </p>
      </Modal>
    </>
  );
}

function AdminId({
  id = "",
  data = {},
  options = {},
  sendConfirmEmail = () => {},
  sendUpdateEmail = () => {},
  disabled,
}) {
  return (
    <Container>
      <h4 className="topic">จัดการผู้ใช้แอดมิน</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "จัดการผู้ใช้แอดมิน", link: "/admin" },
            {
              label: data?.adminCode || 999999,
              current: true,
            },
          ]}
        />
        <div>
          <Confirmation sendConfirmEmail={sendConfirmEmail} />
          <UpdateStatus sendUpdateEmail={sendUpdateEmail} />
          <Link to={`/admin/${id}/edit`}>
            <button className={cx(styles.editBtn)} disabled={disabled}>
              แก้ไข
            </button>
          </Link>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.info}>
          <DisplayValue
            className={styles.agentCode}
            label="รหัสพนักงาน"
            value={data.adminCode}
          />
          <DisplayValue
            className={styles.status}
            label="สถานะ"
            value={
              (options?.statusOptions || []).find(
                (statusOption) => statusOption.value === data.status
              )?.label || "-"
            }
          />
          <div className={styles.nameTh}>
            <b>ชื่อภาษาไทย</b>
          </div>
          <DisplayValue
            className={styles.prefixNameTh}
            label="คำนำหน้า"
            value={data.prefixName}
          />
          <DisplayValue
            className={styles.firstNameTh}
            label="ชื่อจริง"
            value={data.firstName}
          />
          <DisplayValue
            className={styles.lastNameTh}
            label="นามสกุล"
            value={data.lastName}
          />
          <div className={styles.nameEn}>
            <b>ชื่อภาษาอังกฤษ</b>
          </div>
          <DisplayValue
            className={styles.prefixNameEn}
            label="คำนำหน้า"
            value={
              (options?.prefixNameEnOptions || []).find(
                (prefixNameEnOption) =>
                  prefixNameEnOption.value === data.prefixNameEn
              )?.label || "-"
            }
          />
          <DisplayValue
            className={styles.firstNameEn}
            label="ชื่อจริง"
            value={data.firstNameEn}
          />
          <DisplayValue
            className={styles.lastNameEn}
            label="นามสกุล"
            value={data.lastNameEn}
          />
          <DisplayValue
            className={styles.tel}
            label="เบอร์โทรศัพท์"
            value={phoneNumberFormat(data.phoneNumber)}
          />
          <DisplayValue
            className={styles.email}
            label="อีเมล"
            value={data.email}
          />
          <DisplayValue
            className={styles.position}
            label="ตำแหน่ง"
            value={
              (options?.adminTypeOptions || []).find(
                (adminTypeOption) => adminTypeOption.value === data.adminType
              )?.label || "-"
            }
          />
          <DisplayValue
            className={styles.startedDate}
            label="วันที่เริ่มงาน"
            value={dateFormatterFromPattern(data.activatedAt)}
          />
          {data.adminType === "area_admin" ? (
            <DisplayValue
              className={styles.branchCode}
              label="พื้นที่"
              value={
                (options?.areaOptions || []).find(
                  (areaOption) => areaOption.value === data.areaCode
                )?.label || "-"
              }
            />
          ) : (
            data.adminType === "branch_admin" && (
              <DisplayValue
                className={styles.branchCode}
                label="สาขา"
                value={
                  (options?.branchOptions || []).find(
                    (branchOption) => branchOption.value === data.branchCode
                  )?.label || "-"
                }
              />
            )
          )}
        </div>
        <section className={styles.notice}>
          <div className={styles.topic}>
            <b>ตั้งค่าการแจ้งเตือน</b>
          </div>
          <div className={styles.permission}>
            <div className={cx(styles.header, styles.checkbox)}>
              สิทธิ์ใช้งาน
            </div>
            <div className={cx(styles.header, styles.text)}>
              เมนูจัดการผู้สมัคร
            </div>
            <div className={cx(styles.checkbox, styles.radio)}>
              <input
                type="radio"
                name="notificationRecruit"
                checked={data.notificationRecruit === "all"}
              />
              <span className={styles.checkmark}></span>
            </div>
            <div className={cx(styles.text)}>
              ได้รับการแจ้งเตือนจากผู้สมัครทั้งหมด
            </div>
            <div className={cx(styles.checkbox, styles.radio, styles.isOdd)}>
              <input
                type="radio"
                name="notificationRecruit"
                checked={data.notificationRecruit === "assign_only"}
              />
              <span className={styles.checkmark}></span>
            </div>
            <div className={cx(styles.text, styles.isOdd)}>
              ได้รับการแจ้งเตือนเฉพาะผู้สมัครที่เป็นผู้รับผิดชอบหรือผู้สัมภาษณ์เท่านั้น
            </div>
          </div>
        </section>
        {data.adminCode && (
          <Formik enableReinitialize initialValues={data}>
            {() => (
              <Permission options={options.adminPermissions || []} disabled />
            )}
          </Formik>
        )}
      </div>
    </Container>
  );
}

export default authorize(enhancer(AdminId), {
  roles: "admin",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["modify"],
});
