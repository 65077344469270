import { combineReducers } from "@reduxjs/toolkit";

import appReducer from "store/appSlice";
import authReducer from "store/authSlice";
import couterReducer from "store/counterSlice";
import agentReducer from "store/agentSlice";
import teamReducer from "store/teamSlice";
import marketingReducer from "store/marketingSlice";
import affiliateReducer from "./affiliateSlice";
import adminReducer from "./adminSlice";
import consentReducer from "./consentSlice";
import announcementsReducer from "./announcementsSlice";
import commissionReducer from "./commissionSlice";
import notificationReducer from "./notificationSlice";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  couter: couterReducer,
  agent: agentReducer,
  team: teamReducer,
  marketing: marketingReducer,
  affiliate: affiliateReducer,
  admin: adminReducer,
  consent: consentReducer,
  announcements: announcementsReducer,
  commission: commissionReducer,
  notification: notificationReducer,
});

export default rootReducer;
