import React from "react";
import cx from "classnames";
import { Formik } from "formik";

import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";
import DatePicker from "components/Form/Element/DatePicker";

import styles from "./FilterBar.module.css";

const initialValues = {
  agentKey: "",
  firstName: "",
  lastName: "",
  status: "",
  activatedSearch: null,
  orderBy: "",
  order: "desc",
  page: 1,
  perPage: 10,
};

function FilterBar({
  isOpen,
  options = { affiliateStatusOptions: [] },
  onChange = () => {},
}) {
  return (
    <Formik initialValues={initialValues}>
      {(props) => {
        const { resetForm } = props;
        return (
          <form className={cx(styles.filterWrapper, !isOpen ? "hidden" : null)}>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="agentCode">รหัสผู้แนะนำ</label>
              <Input
                name="agentKey"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                useTimeout={1500}
                onChange={(value) => onChange({ agentKey: value, page: 1 })}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="firstName">ชื่อ</label>
              <Input
                name="firstName"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                useTimeout={1500}
                onChange={(value) => onChange({ firstName: value, page: 1 })}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="lastName">นามสกุล</label>
              <Input
                name="lastName"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                useTimeout={1500}
                onChange={(value) => onChange({ lastName: value, page: 1 })}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="status">สถานะ</label>
              <Selector
                width="100%"
                name="status"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) => onChange({ status: value, page: 1 })}
                options={options.affiliateStatusOptions}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="activatedSearch">วันเริ่มงาน</label>
              <DatePicker
                width="100%"
                name="activatedSearch"
                className={styles.filterInput}
                onChange={(value) =>
                  onChange({ activatedSearch: value, page: 1 })
                }
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <div
                className={cx(styles.filterInput, styles.reset)}
                onClick={() => {
                  resetForm();
                  onChange(initialValues);
                }}
              >
                รีเซ็ตค่าตัวเลือก
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FilterBar;
