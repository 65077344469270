import Icon from "components/Icon";
import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";
import styles from "./Documents.module.css";
import { Fragment } from "react";
import { FieldArray } from "formik";
import cx from "classnames";
import DatePicker from "components/Form/Element/DatePicker";
import { getYearsOptions } from "../Element/DatePicker/DatePicker.utils";
import { dateFormatterFromPattern } from "utils/formatter";

const Documents = ({
  values,
  options,
  disabled = true,
  handleOpen = () => {},
}) => {
  return (
    <section className={styles.documents}>
      {values.archiveDocuments && values.archiveDocuments.length > 0 && (
        <div className={styles.archiveButton}>
          <button type="button" onClick={handleOpen}>
            เอกสารที่เคยยื่นสมัคร
          </button>
        </div>
      )}
      <div className={styles.agentDocuments}>
        <div className={styles.title}>
          <b>เอกสารการสมัคร</b>
        </div>
        <div className={styles.header}>
          <div style={{ paddingLeft: 16 }}>เอกสาร</div>
          <div>สถานะ</div>
          <div>วันอัปเดต</div>
          <div>คำแนะนำ</div>
        </div>
        <FieldArray
          name="agentDocuments"
          render={(arrayHelpers) => {
            const addDocument = () =>
              arrayHelpers.push({
                name: "",
                description: "",
                status: "new",
              });

            return (
              <>
                {(values.agentDocuments || []).map((agentDocument, index) => {
                  const onRemove = () => arrayHelpers.remove(index);

                  return (
                    <Fragment key={index}>
                      {!agentDocument.isDefault &&
                      !agentDocument.documentImageId ? (
                        <div className={styles.newRow}>
                          <div className={styles.groupDescription}>
                            <div className={styles.bin} onClick={onRemove}>
                              <Icon name="close" />
                            </div>
                            <Input
                              className={styles.name}
                              name={`agentDocuments.${index}.name`}
                              placeholder="ระบุชื่อเอกสาร"
                            />
                          </div>
                          <Input
                            className={styles.description}
                            name={`agentDocuments.${index}.description`}
                            placeholder="ระบุคำแนะนำ"
                          />
                        </div>
                      ) : (
                        <div className={styles.row}>
                          <div className={styles.name}>
                            <Icon
                              name="upload"
                              className={
                                agentDocument.documentImageId
                                  ? styles.iconUpload
                                  : styles.iconUploadDisabled
                              }
                            />
                            <label
                              style={
                                agentDocument.documentImageId
                                  ? {
                                      color: "var(--agt-primary-color)",
                                    }
                                  : {
                                      color: "var(--agt-secondary-color-1)",
                                      cursor: "not-allowed",
                                    }
                              }
                            >
                              {agentDocument?.name || ""}
                            </label>
                          </div>
                          <Selector
                            name={`agentDocuments.${index}.status`}
                            className={styles.status}
                            options={(
                              options?.documentStatusOptions || []
                            ).filter(
                              (option) => !["expired"].includes(option.value)
                            )}
                            disabled={
                              !agentDocument.documentImageId || disabled
                            }
                            width="100%"
                          />
                          <div>
                            {dateFormatterFromPattern(agentDocument.updatedAt)}
                          </div>
                          <Input
                            className={styles.description}
                            name={`agentDocuments.${index}.description`}
                            placeholder="ระบุคำแนะนำ"
                            disabled={disabled}
                          />
                          {!agentDocument.isDefault && (
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              className={`secondary ${styles.newBin}`}
                            >
                              <Icon name="bin" />
                            </button>
                          )}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
                <button
                  type="button"
                  className={cx("secondary", styles.addDocuments)}
                  onClick={addDocument}
                  disabled={disabled}
                >
                  + เพิ่มรายการเอกสาร
                </button>
              </>
            );
          }}
        />
      </div>
      <div className={styles.agentImportant}>
        <div className={styles.title}>
          <b>สำเนาเอกสารสำคัญ</b>
        </div>
        <FieldArray
          name="agentImportantDocuments"
          render={(arrayHelpers) => {
            const addDocument = () =>
              arrayHelpers.push({
                name: "",
                description: "",
                status: "new",
              });

            return (
              <>
                {(values.agentImportantDocuments || []).map(
                  (agentImportantDocument, index) => {
                    const onRemove = () => arrayHelpers.remove(index);

                    return (
                      <Fragment key={index}>
                        {!agentImportantDocument.isDefault &&
                        !agentImportantDocument.importantDocumentImageId ? (
                          <div className={styles.newRow}>
                            <div className={styles.groupDescription}>
                              <div className={styles.bin} onClick={onRemove}>
                                <Icon name="close" />
                              </div>
                              <Input
                                className={styles.name}
                                name={`agentImportantDocuments.${index}.name`}
                                placeholder="ระบุชื่อเอกสาร"
                              />
                            </div>
                            <Input
                              className={styles.description}
                              name={`agentImportantDocuments.${index}.description`}
                              placeholder="ระบุคำแนะนำ"
                            />
                          </div>
                        ) : (
                          <div className={styles.row}>
                            <div className={styles.name}>
                              <Icon
                                name="upload"
                                className={
                                  agentImportantDocument.importantDocumentImageId
                                    ? styles.iconUpload
                                    : styles.iconUploadDisabled
                                }
                              />
                              <label
                                style={
                                  agentImportantDocument.importantDocumentImageId
                                    ? {
                                        color: "var(--agt-primary-color)",
                                      }
                                    : {
                                        color: "var(--agt-secondary-color-1)",
                                        cursor: "not-allowed",
                                      }
                                }
                              >
                                {agentImportantDocument.name}
                              </label>
                            </div>
                            <Selector
                              name={`agentImportantDocuments.${index}.status`}
                              className={styles.status}
                              options={(
                                options?.documentStatusOptions || []
                              ).filter(
                                (option) => !["expired"].includes(option.value)
                              )}
                              disabled={
                                !agentImportantDocument.importantDocumentImageId ||
                                disabled
                              }
                              width="100%"
                            />
                            <div>
                              {dateFormatterFromPattern(
                                agentImportantDocument.updatedAt
                              )}
                            </div>
                            <Input
                              className={styles.description}
                              name={`agentImportantDocuments.${index}.description`}
                              placeholder="ระบุคำแนะนำ"
                              disabled={disabled}
                            />
                            {!agentImportantDocument.isDefault && (
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                className={`secondary ${styles.newBin}`}
                              >
                                <Icon name="bin" />
                              </button>
                            )}
                          </div>
                        )}
                      </Fragment>
                    );
                  }
                )}
                <button
                  type="button"
                  className={cx("secondary", styles.addDocuments)}
                  onClick={addDocument}
                  disabled={disabled}
                >
                  + เพิ่มรายการเอกสาร
                </button>
              </>
            );
          }}
        />
      </div>
      <div className={styles.agentLicense}>
        <div className={styles.title}>
          <b>เลขที่ใบอนุญาต</b>
        </div>
        <div className={styles.header}>
          <div style={{ paddingLeft: 16 }}>เลขที่</div>
          <div>ประเภทใบอนุญาต</div>
          <div>สถานะ</div>
          <div>วันหมดอายุ</div>
          <div>วันที่อัปเดต</div>
        </div>
        <FieldArray
          name="agentLicenses"
          render={(arrayHelpers) => {
            const addDocument = () =>
              arrayHelpers.push({
                licenseCode: "",
                licenseImageId: "",
                status: "new",
                licenseType: "ตัวแทนประกันชีวิต",
              });

            return (
              <>
                {(values.agentLicenses || []).map((agentLicense, index) => {
                  return (
                    <Fragment key={index}>
                      {!agentLicense.licenseImageId ? (
                        <div className={styles.newSubrow}>
                          <Selector
                            name={`agentLicenses.${index}.licenseType`}
                            className={styles.licenseType}
                            options={options?.licenseTypeOptions}
                            width="100%"
                          />
                          <div className={styles.groupDescription}>
                            <div
                              className={styles.bin}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Icon name="close" />
                            </div>
                            <Input
                              className={styles.description}
                              name={`agentLicenses.${index}.description`}
                              placeholder="ระบุคำแนะนำ"
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.row}>
                            <div
                              style={{
                                paddingLeft: 16,
                                paddingRight: 8,
                              }}
                              className={styles.name}
                            >
                              <Icon
                                name="upload"
                                className={styles.iconUpload}
                              />
                              <Input
                                className={styles.licenseId}
                                name={`agentLicenses.${index}.licenseCode`}
                                placeholder="โปรดระบุ"
                                disabled={
                                  disabled ||
                                  agentLicense.status === "expired" ||
                                  !agentLicense.licenseImageId
                                }
                              />
                            </div>
                            <Selector
                              name={`agentLicenses.${index}.licenseType`}
                              className={styles.licenseType}
                              options={options?.licenseTypeOptions}
                              disabled={
                                disabled || agentLicense.status === "expired"
                              }
                              width="100%"
                            />
                            <Selector
                              name={`agentLicenses.${index}.status`}
                              className={styles.status}
                              options={
                                agentLicense.status === "expired"
                                  ? options?.documentStatusOptions
                                  : (
                                      options?.documentStatusOptions || []
                                    ).filter(
                                      (option) =>
                                        !["expired"].includes(option.value)
                                    )
                              }
                              disabled={
                                disabled || agentLicense.status === "expired"
                              }
                              width="100%"
                            />
                            <DatePicker
                              className={styles.endDate}
                              name={`agentLicenses.${index}.licenseExpiredAt`}
                              disabled={
                                disabled || agentLicense.status === "expired"
                              }
                              yearOptions={getYearsOptions(null, "desc", 50)}
                              showSelector
                            />
                            <small>
                              {dateFormatterFromPattern(agentLicense.updatedAt)}
                            </small>
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              className={`secondary ${styles.newBin}`}
                            >
                              <Icon name="bin" />
                            </button>
                          </div>
                          <div className={styles.subrow}>
                            <div />
                            <Input
                              className={styles.description}
                              name={`agentLicenses.${index}.description`}
                              placeholder="ระบุคำแนะนำ"
                              disabled={disabled}
                            />
                          </div>
                        </>
                      )}
                    </Fragment>
                  );
                })}
                {values.agentLicenses.length < 5 && (
                  <button
                    type="button"
                    className={cx("secondary", styles.addLicenses)}
                    onClick={addDocument}
                    disabled={disabled}
                  >
                    + เพิ่มรายการใบอนุญาต
                  </button>
                )}
              </>
            );
          }}
        />
      </div>
    </section>
  );
};

export default Documents;
