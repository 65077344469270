import Logo from "assets/images/agent_portal_backoffice.png";
import styles from "./Header.module.css";
import enhancer from "./Header.compose";
import DEFAULT_AVATAR from "assets/images/avatar_agent.png";
import { Fragment } from "react";
import Icon from "components/Icon";
import moment from "moment/moment";
import "moment/locale/th";

const Dropdown = ({
  data,
  personal,
  children,
  isNotice = false,
  wrapperRef,
  notificationToday = [],
  notificationLated = [],
  haveIsChecked = false,
  setOpenChecked = () => {},
  setOpenToRead = () => {},
}) => {
  return (
    <div className={styles.avatar}>
      <div
        ref={wrapperRef}
        className={styles.notification}
        onClick={() => {
          if (!isNotice) {
            setOpenChecked();
          }
        }}
      >
        {haveIsChecked && !isNotice && <span />}
        <Icon name="notice" className={styles.icon} />
        {isNotice && (
          <div className={styles.info}>
            <p>การแจ้งเตือน</p>
            {notificationToday.length > 0 || notificationLated.length > 0 ? (
              <>
                {notificationToday.length > 0 && (
                  <div>
                    <p>ใหม่</p>
                    <ul>
                      {notificationToday.slice(0, 5).map((item, index) => (
                        <li
                          key={index}
                          className={item.isRead ? styles.isRead : {}}
                          onClick={() => {
                            setOpenToRead(item);
                          }}
                        >
                          <div className={styles.detail}>
                            <Icon name="info-noti" />
                            <div>
                              <p className={styles.superText}>
                                <b>{item.actionName}</b> {item.agentName}
                              </p>
                              {item.actionType === "recruitment" && (
                                <p>
                                  ได้ยื่นสมัคร <b>{item.subActionType}</b>
                                  <br />
                                  โปรดตรวจสอบข้อมูลของผู้สมัคร
                                </p>
                              )}
                              {item.actionType === "document" && (
                                <p>
                                  มีการอัปโหลดเอกสารเพิ่มเติม
                                  <br />
                                  โปรดตรวจสอบข้อมูลของผู้สมัคร
                                </p>
                              )}
                            </div>
                          </div>
                          <div className={styles.time}>
                            <small>
                              {moment(item.alertAt).locale("th").fromNow()}
                            </small>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {notificationLated.length > 0 && (
                  <div>
                    <p>ก่อนหน้านี้</p>
                    <ul>
                      {notificationLated.slice(0, 5).map((item, index) => (
                        <li
                          key={index}
                          className={item.isRead ? styles.isRead : {}}
                          onClick={() => {
                            setOpenToRead(item);
                          }}
                        >
                          <div className={styles.detail}>
                            <Icon name="info-noti" />
                            <div>
                              <p className={styles.superText}>
                                <b>{item.actionName}</b> {item.agentName}
                              </p>
                              {item.actionType === "recruitment" && (
                                <p>
                                  ได้ยื่นสมัคร <b>{item.subActionType}</b>
                                  <br />
                                  โปรดตรวจสอบข้อมูลของผู้สมัคร
                                </p>
                              )}
                              {item.actionType === "document" && (
                                <p>
                                  มีการอัปโหลดเอกสารเพิ่มเติม
                                  <br />
                                  โปรดตรวจสอบข้อมูลของผู้สมัคร
                                </p>
                              )}
                            </div>
                          </div>
                          <div className={styles.time}>
                            <small>
                              {moment(item.alertAt).locale("th").fromNow()}
                            </small>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <p>
                  <a href="/notification">ดูทั้งหมด</a>
                </p>
              </>
            ) : (
              <p>ยังไม่มีการแจ้งเตือน</p>
            )}
          </div>
        )}
      </div>
      <div className={styles.adminName}>
        <img
          className={styles.user}
          src={data.authorImagePath || DEFAULT_AVATAR}
          alt="News avatar"
        />
        <label>{data.adminName}</label>
        <div className={styles.dropdown}>
          <div className={styles.layout}>
            <div className={styles.chevronTop} />
            <div className={styles.personal}>{personal}</div>
            <ul className={styles.content}>{children}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

function Header({
  data,
  onLogout,
  isNotice,
  setIsNotice,
  notifications,
  notificationToday,
  notificationLated,
  wrapperRef,
  haveIsChecked,
  setOpenChecked,
  setOpenToRead,
}) {
  return (
    <header id="header" className={styles.container}>
      <div className={styles.wrapper}>
        <img className={styles.logo} src={Logo} alt="TQM agent portal logo" />
        {data && data?.adminType && (
          <Dropdown
            data={data}
            personal={
              <Fragment>
                <small className={styles.potition}>
                  <div>ตำแหน่ง : {data.adminTypeTh}</div>
                  <span className={styles.adminCode}>{data.adminCode}</span>
                </small>
                <small>สถานะ : {data.statusTh}</small>
              </Fragment>
            }
            isNotice={isNotice}
            setIsNotice={setIsNotice}
            notifications={notifications}
            notificationToday={notificationToday}
            notificationLated={notificationLated}
            wrapperRef={wrapperRef}
            haveIsChecked={haveIsChecked}
            setOpenChecked={setOpenChecked}
            setOpenToRead={setOpenToRead}
          >
            {/* <li>ดูประวัติย้อนหลัง</li> */}
            <li onClick={onLogout}>
              <b>ออกจากระบบ</b>
            </li>
          </Dropdown>
        )}
      </div>
    </header>
  );
}

export default enhancer(Header);
