import { Link } from "react-router-dom";
import cx from "classnames";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import DisplayValue from "components/DisplayValue";
import Modal from "components/Modal";
import CheckboxMockup from "components/Mockup/Checkbox";

import useToggle from "hooks/useToggle";
import enhancer from "./Consent.compose";
import styles from "./Consent.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import Preview from "components/Form/ConsentForm/Preview";
import { dateFormat } from "utils/formatter";
import authorize, { UnauthorizedAction } from "utils/authorize";

const DeleteConsent = authorize(
  ({ onDelete = () => {}, disabled }) => {
    const [showModal, , handleOpenModal, handleCloseModal] = useToggle();
    return (
      <>
        <button
          type="button"
          className={cx("secondary", styles.btn)}
          onClick={handleOpenModal}
          disabled={disabled}
        >
          ลบข้อตกลง
        </button>
        <Modal
          isOpen={showModal}
          onSubmit={() => {
            onDelete();
            handleCloseModal();
          }}
          onCancel={handleCloseModal}
          submitText={"ยืนยันลบข้อตกลง"}
          cancelText={"ยกเลิก"}
          contentLabel="delete consent modal"
        >
          <p>
            เมื่อลบแล้วจะไม่สามารถกู้คืนได้อีก
            <br />
            คุณต้องการยืนยันการลบใช่หรือไม่
          </p>
        </Modal>
      </>
    );
  },
  {
    roles: "agreement",
    params: { unauthorizedAction: UnauthorizedAction.DISABLED },
    actions: ["delete"],
  }
);

function Consent({
  id = "",
  data = {},
  options = { agreementTypeOptions: [] },
  onDelete = () => {},
  disabled,
}) {
  return (
    <Container>
      <h4 className="topic">จัดการข้อตกลงและการยินยอม</h4>
      <div
        className={cx(containerStyles.breadcrumbWithButton, styles.breadcrumb)}
      >
        <Breadcrumb
          links={[
            { label: "จัดการข้อตกลงและการยินยอม", link: "/consent" },
            {
              label: data?.name || "สร้าง",
              current: true,
            },
          ]}
        />
        <div className={styles.groupBtn}>
          {data?.status === "draft" && <DeleteConsent onDelete={onDelete} />}
          {data?.status === "draft" && (
            <Link to={`/consent/${id}/edit`}>
              <button
                className={cx(styles.editBtn, styles.btn)}
                disabled={disabled}
              >
                แก้ไข
              </button>
            </Link>
          )}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.info}>
          <DisplayValue
            className={styles.name}
            label="ชื่อ"
            value={data.name}
          />
          <DisplayValue
            className={styles.status}
            label="ผู้ให้การยินยอม"
            value={
              (options?.consentByOptions || []).find(
                (consentByOption) => consentByOption.value === data.consentBy
              )?.label || "-"
            }
          />
          <DisplayValue
            className={styles.type}
            label="ประเภท"
            value={
              (options?.agreementTypeOptions || []).find(
                (agreementTypeOption) =>
                  agreementTypeOption.value === data.agreementType
              )?.label || "-"
            }
          />
          <DisplayValue
            className={styles.contentDisplay}
            label="ส่วนแสดงเนื้อหา"
            value={
              (options?.contentDisplayOptions || []).find(
                (contentDisplayOption) =>
                  contentDisplayOption.value === data.contentDisplay
              )?.label || "-"
            }
          />
          <DisplayValue
            className={styles.version}
            label="เวอร์ชัน"
            value={data.version}
          />
          <DisplayValue
            className={styles.status}
            label="สถานะ"
            value={
              (options?.statusOptions || []).find(
                (statusOption) => statusOption.value === data.status
              )?.label || "-"
            }
          />
          <DisplayValue
            className={styles.beginDate}
            label="วันที่มีผลบังคับใช้"
            value={data.beginDate ? dateFormat(data.beginDate) : "-"}
          />
        </div>
        <DisplayValue
          className={styles.consent}
          label="เนื้อหา"
          value={data.detail}
          useDangerously
        />
        {(data.consents || []).map((c, index) => {
          return (
            <div key={index} className={styles.consentContainer}>
              <CheckboxMockup className={styles.checkbox} />
              <div className={styles.consentText}>{c.name}</div>
            </div>
          );
        })}
        <Preview
          title={data.name}
          text={data.detail}
          consents={data.consents}
        />
      </div>
    </Container>
  );
}

export default authorize(enhancer(Consent), {
  roles: "agreement",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["modify", "add"],
});
