import Icon from "components/Icon";
import styles from "./Archive.module.css";
import cx from "classnames";
// import { dateFormatterFromPattern } from "utils/formatter";

const Archive = ({
  className,
  data = [],
  explain = "",
  handleClose = () => {},
  getFileDocument = () => {},
}) => {
  return (
    <section className={cx(styles.archivies, className)}>
      {explain && (
        <div className={styles.insertExplain}>
          <div className={styles.title}>
            <label>ชี้แจงสำคัญ</label>
            <Icon name="info" className={styles.iconInfo} />
          </div>
          <div className={styles.explain}>{explain}</div>
        </div>
      )}
      <div className={styles.archiveButton}>
        <button type="button" onClick={handleClose}>
          เอกสารที่เคยยื่นสมัคร
        </button>
      </div>
      {data.map((e, i) => (
        <div
          key={i}
          className={
            i === 0 ? cx(styles.archive, styles.firstArchive) : styles.archive
          }
        >
          <div className={styles.archiveDate}>
            <b>เอกสารที่จะถูกลบถาวรวันที่</b>{" "}
            <small>{e.archiveDeletedAt}</small>
          </div>
          <div className={styles.agentDocuments}>
            <div className={styles.title}>
              <b>เอกสารการสมัคร</b>
            </div>
            {e.values.agentDocuments &&
              e.values.agentDocuments.length > 0 &&
              e.values.agentDocuments.map((agentDocument) => (
                <div className={styles.row}>
                  <div className={styles.name}>
                    <Icon name="upload" className={styles.iconUpload} />
                    <label
                      style={{ color: "var(--agt-primary-color)" }}
                      onClick={() => {
                        if (
                          agentDocument?.documentImageId &&
                          agentDocument?.id &&
                          agentDocument?.name
                        )
                          getFileDocument(
                            {
                              type: "document",
                              downloadId: agentDocument.id,
                            },
                            agentDocument.name
                          );
                      }}
                    >
                      {agentDocument?.name || ""}
                    </label>
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.agentImportant}>
            <div className={styles.title}>
              <b>สำเนาเอกสารสำคัญ</b>
            </div>
            {e.values.agentImportantDocuments &&
              e.values.agentImportantDocuments.length > 0 &&
              e.values.agentImportantDocuments.map((agentImportantDocument) => (
                <div className={styles.row}>
                  <div className={styles.name}>
                    <Icon name="upload" className={styles.iconUpload} />
                    <label
                      style={{ color: "var(--agt-primary-color)" }}
                      onClick={() => {
                        if (
                          agentImportantDocument?.importantDocumentImageId &&
                          agentImportantDocument?.id &&
                          agentImportantDocument?.name
                        )
                          getFileDocument(
                            {
                              type: "important_document",
                              downloadId: agentImportantDocument.id,
                            },
                            agentImportantDocument.name
                          );
                      }}
                    >
                      {agentImportantDocument?.name || ""}
                    </label>
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.agentLicense}>
            <div className={styles.title}>
              <b>เอกสารการสมัคร</b>
            </div>
            <div className={styles.header}>
              <div style={{ paddingLeft: 16 }}>เลขที่</div>
              <div>ประเภทใบอนุญาต</div>
            </div>
            {e.values.agentLicenses &&
              e.values.agentLicenses.length > 0 &&
              e.values.agentLicenses.map((agentLicense) => (
                <div className={styles.row}>
                  <div className={styles.name}>
                    <Icon name="upload" className={styles.iconUpload} />
                    <label
                      style={{ color: "var(--agt-primary-color)" }}
                      onClick={() => {
                        if (agentLicense.licenseCode && agentLicense.id)
                          getFileDocument(
                            {
                              type: "agent_license",
                              downloadId: agentLicense.id,
                            },
                            agentLicense.licenseCode
                          );
                      }}
                    >
                      {agentLicense?.licenseCode || ""}
                    </label>
                  </div>
                  <div>{agentLicense?.licenseType || ""}</div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default Archive;
