import { createSlice } from "@reduxjs/toolkit";
import api from "api";

const notificationInitialState = {
  notifications: null,
  errorMessage: "",
};

const notificationReducers = {
  setNotification(state, action) {
    state.notifications = action.payload;
  },
  setErrorMessage(state, action) {
    state.errorMessage = action.payload;
  },
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitialState,
  reducers: notificationReducers,
});

export const { setNotification, setErrorMessage } = notificationSlice.actions;

export default notificationSlice.reducer;

export const getNotifications = () => async (dispatch) => {
  try {
    const response = await api.get("/notifications");
    dispatch(setNotification(response.data.data.notifications));
  } catch (error) {
    console.error(error.response || error);
  } finally {
  }
};

export const markAsRead = (ids = []) => async () => {
  try {
    await api.patch("/notifications/mark_as_read", { ids });
  } catch (error) {
    console.error(error.response || error);
  } finally {
  }
};

export const markAsCheck = (ids = []) => async () => {
  try {
    await api.patch("/notifications/mark_as_check", { ids });
  } catch (error) {
    console.error(error.response || error);
  } finally {
  }
};
