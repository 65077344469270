import { useState, useEffect } from "react";
import Icon from "components/Icon";
import cx from "classnames";

import Modal from "components/Modal";
import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";
import { Selector as Select } from "components/Form/Element/Selector/Selector";

import useToggle from "hooks/useToggle";
import { dateFormatterFromPattern } from "utils/formatter";
import styles from "./AgentForm.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";
import DatePicker from "../Element/DatePicker";

const NewInterviewComponent = ({
  label = "",
  role = "",
  values = null,
  onChange = () => {},
}) => {
  return (
    <div className={styles.interviewContainer}>
      <div style={{ marginBottom: 8 }}>
        <label>
          {label} : <b>{role}</b>
        </label>
      </div>
      <div className={styles.interviewGroup}>
        <div className={styles.interviewButton}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => onChange(true)}
          >
            <div
              className={cx(
                styles.interviewCircle,
                values === true ? styles.interviewChecked : null
              )}
            >
              <div className={values === true ? styles.dot : ""} />
            </div>
            <div style={{ paddingLeft: 16 }}>ผ่าน</div>
          </div>
        </div>
        <div className={styles.interviewButton}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => onChange(false)}
          >
            <div
              className={cx(
                styles.interviewCircle,
                values === false ? styles.interviewChecked : null
              )}
            >
              <div className={values === false ? styles.dot : null} />
            </div>
            <div style={{ paddingLeft: 16 }}>ไม่ผ่าน</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckboxAutorize = ({
  name,
  value,
  label,
  onClick = () => {},
  disabled = false,
}) => {
  return (
    <div className={styles.checkboxAutorize}>
      <div
        className={
          disabled
            ? styles.disabledCheckbox
            : value
            ? styles.checkedbox
            : styles.checkbox
        }
      >
        <Icon
          name="check"
          className={value ? styles.checked : styles.unChecked}
        />
        <input
          type="checkbox"
          name={name}
          checked={value}
          onClick={onClick}
          className={styles.checkboxInput}
          disabled={disabled}
        />
      </div>
      <label htmlFor={name}>{label}</label>
    </div>
  );
};

const RejectEmail = authorize(
  ({ sendEmail = () => {}, options, data = {}, name = "", disabled }) => {
    const [showModal, , handleOpenModal, handleCloseModal] = useToggle();
    const [param, setParam] = useState({
      label: "",
      value: "",
    });
    const [checked, setChecked] = useState("period");

    const onSubmit = () => {
      handleCloseModal();
      let values = {};
      if (data?.agentType === "guest") {
        values = {
          ...data,
          recruits: {
            ...data.recruits,
            [name]: {
              ...data.recruits[name],
              agentStatus: param.value,
              recruitStatus: "failed",
            },
          },
        };
      } else {
        values = {
          ...data,
          recruits: {
            ...data.recruits,
            [name]: {
              ...data.recruits[name],
              nextRecruitmentedAt:
                checked === "period" ? param.value : data?.nextRecruitmentedAt,
              recruitStatus: "failed",
            },
          },
        };
      }
      sendEmail(
        values,
        name === "affiliate"
          ? "affiliate"
          : name === "lifeBroker"
          ? "life_broker"
          : "non_life_broker",
        name,
        "failed"
      );
    };

    const onCancel = () => {
      handleCloseModal();
    };

    useEffect(() => {
      if (
        data &&
        options?.agentStatusOptions?.length > 0 &&
        options?.nextRecruitmentedOptions?.length > 0
      ) {
        if (data.agentType === "guest") {
          setParam({
            label: "ปฏิเสธถาวร (Blacklist)",
            value: "ปฏิเสธถาวร (Blacklist)",
          });
        } else {
          setParam(options.nextRecruitmentedOptions[0]);
        }
      }
    }, [data, options, setParam]);

    useEffect(() => {
      if (
        ["affiliate", "life_broker", "non_life_broker"].includes(
          data?.agentType
        )
      ) {
        if (
          checked === "period" &&
          options?.nextRecruitmentedOptions?.length > 0
        ) {
          setParam(options.nextRecruitmentedOptions[0]);
        } else if (checked === "date") {
        }
      }
    }, [checked, data, options, setParam]);

    return (
      <>
        <Modal
          className={styles.modal}
          isOpen={showModal}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={handleCloseModal}
          contentLabel="SendEmail Modal"
          submitText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          {data?.agentType === "guest" ? (
            <div className={styles.rejectEmail}>
              <p>
                ต้องการ<b>ไม่อนุมัติ</b>ผู้สมัครท่านนี้ใช่หรือไม่ ?<br />
                กรุณาระบุสถานะการปฏิเสธ
                <br />
                โดยระบบจะส่งอีเมลปฏิเสธไปยังผู้สมัคร
              </p>
              <Select
                width="100%"
                value={param}
                className={styles.selectRejectEmail}
                options={
                  (options?.agentStatusOptions || []).filter((option) =>
                    [
                      "ปฏิเสธถาวร (Blacklist)",
                      "ปฏิเสธชั่วคราว (1 ปี)",
                    ].includes(option.value)
                  ) || []
                }
                onChange={(e) => {
                  setParam(e);
                }}
                placeholder="เลือก"
                usePortal={false}
              />
              <small>
                เมื่อกดยืนยันแล้วจะไม่สามารถแก้ไขรายละเอียดในภายหลังได้
              </small>
            </div>
          ) : (
            <div className={styles.rejectEmail}>
              <p>
                ต้องการ<b>ไม่อนุมัติ</b>ผู้สมัครท่านนี้ใช่หรือไม่ ?<br />
                เนื่องจากผู้สมัครเป็น
                {data?.agentType === "affiliate"
                  ? "ผู้แนะนำ"
                  : data?.agentType === "life_broker"
                  ? "นายหน้าประกันชีวิต"
                  : "นายหน้าประกันภัย"}
                อยู่แล้ว
                <br />
                สถานะสมัครจะกลับไปเป็น อนุมัติเสร็จสิ้น
                <br />
                ดังเดิม
              </p>
              <p>
                {`กรุณาระบุวันที่ `}
                <span>{`${data?.firstName} ${data?.lastName}`}</span>
                {` ยื่นสมัคร`}
                <br />
                <b>
                  {name === "affiliate"
                    ? "ผู้แนะนำ"
                    : name === "lifeBroker"
                    ? "นายหน้าประกันชีวิต"
                    : "นายหน้าประกันภัย"}
                </b>
                {` ใหม่ได้`}
              </p>
              <div className={styles.selectTime}>
                <label
                  className={styles.container}
                  onClick={() => setChecked("period")}
                >
                  ระบุตามช่วงเวลา
                  <input
                    type="radio"
                    checked={checked === "period"}
                    name="radio"
                  />
                  <span className={styles.checkmark}></span>
                </label>
                <label
                  className={styles.container}
                  onClick={() => setChecked("date")}
                >
                  ระบุวันที่
                  <input
                    type="radio"
                    checked={checked === "date"}
                    name="radio"
                  />
                  <span className={styles.checkmark}></span>
                </label>
              </div>
              {checked === "period" ? (
                <Select
                  width="100%"
                  value={param}
                  options={options?.nextRecruitmentedOptions || []}
                  onChange={(e) => {
                    setParam(e);
                  }}
                  placeholder="เลือก"
                  usePortal={false}
                />
              ) : (
                <DatePicker
                  className={styles.nextRecruitmented}
                  name="nextRecruitmentedAt"
                  label=""
                  placeholder="โปรดระบุ"
                  showSelector
                  usePortalId={false}
                  minDate={new Date()}
                />
              )}
            </div>
          )}
        </Modal>
        <button
          type="button"
          className="secondary"
          onClick={handleOpenModal}
          disabled={disabled}
        >
          ไม่อนุมัติ
        </button>
      </>
    );
  },
  {
    roles: "agent",
    params: { unauthorizedAction: UnauthorizedAction.DISABLED },
    actions: ["approve_life_broker"],
  }
);

const ApprovedEmail = authorize(
  ({
    sendEmail = () => {},
    role = "ผู้แนะนำ",
    data = {},
    name = "",
    disabled,
  }) => {
    const [showModal, , handleOpenModal, handleCloseModal] = useToggle();

    const onSubmit = () => {
      handleCloseModal();
      let values = {
        ...data,
        recruits: {
          ...data.recruits,
          [name]: {
            ...data.recruits[name],
            recruitStatus: "success",
          },
        },
      };
      sendEmail(
        values,
        name === "affiliate"
          ? "affiliate"
          : name === "lifeBroker"
          ? "life_broker"
          : "non_life_broker",
        "success"
      );
    };
    const onCancel = () => {
      handleCloseModal();
    };

    return (
      <>
        <Modal
          isOpen={showModal}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onClose={handleCloseModal}
          contentLabel="SendEmail Modal"
          submitText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          <div className={styles.approvedEmail}>
            <p>
              ต้องการ<b>อนุมัติ</b>
              ผู้สมัครท่านนี้เป็น
              <br />
              {role}ใช่หรือไม่ ?<br />
              (กรุณาตรวจสอบข้อมูลทีม อัปไลน์ ให้ถูกต้อง)
            </p>
            <small>
              เมื่อกดยืนยันแล้วจะไม่สามารถแก้ไขรายละเอียดในภายหลังได้
            </small>
          </div>
        </Modal>
        <button
          type="button"
          className="primary"
          onClick={handleOpenModal}
          disabled={disabled}
        >
          อนุมัติ
        </button>
      </>
    );
  },
  {
    roles: "agent",
    params: { unauthorizedAction: UnauthorizedAction.DISABLED },
    actions: ["approve_life_broker"],
  }
);

const LifeBrokerForm = ({
  values,
  setFieldValue,
  options,
  errorRecruit,
  handleChangeRecruit,
  updateAgentType,
}) => {
  if (
    values?.recruits?.lifeBroker &&
    ["success", "failed", "expired"].includes(
      values?.recruits?.lifeBroker?.recruitStatus
    )
  ) {
    return (
      <div className={styles.newInterview}>
        <div className={styles.rowInterview}>
          <div className={styles.columnInterview}>
            <div>
              ผลการสัมภาษณ์ : <b>นายหน้าประกันชีวิต</b>
            </div>
          </div>
          <div className={cx(styles.columnInterview)}>
            <div className={styles.activatedAt}>
              {`วันที่สมัคร : `}
              {values.recruits?.lifeBroker?.recruitedAt
                ? dateFormatterFromPattern(
                    values.recruits?.lifeBroker?.recruitedAt
                  )
                : "-"}
            </div>
          </div>
        </div>
        <div className={styles.rowInterview}>
          <div className={styles.columnInterview}>
            <div>
              {values.recruits?.lifeBroker?.interviewStatus
                ? "ผ่าน"
                : "ไม่ผ่าน"}
            </div>
          </div>
          <div className={cx(styles.columnInterview, styles.approvedInterview)}>
            <div className={styles.explain}>
              {values.recruits?.lifeBroker?.note || "-"}
            </div>
          </div>
        </div>
        {["success", "expired"].includes(
          values?.recruits?.lifeBroker?.recruitStatus
        ) && (
          <>
            <div className={styles.rowInterview}>
              <div
                className={cx(styles.columnInterview, styles.approvedInterview)}
              >
                <b>Staff Code</b>
                <div className={styles.explain}>
                  {values.recruits?.lifeBroker?.staffCode || "-"}
                </div>
              </div>
              <div className={cx(styles.rowAutorize, styles.newRowAutorize)}>
                <CheckboxAutorize
                  name="recruits.lifeBroker.isLeader"
                  value={values.recruits?.lifeBroker?.isLeader}
                  label="หัวหน้านายหน้า"
                  disabled={
                    values.recruits?.lifeBroker?.recruitStatus === "expired"
                  }
                  onClick={(e) => {
                    setFieldValue(
                      "recruits.lifeBroker.isLeader",
                      e.target.checked
                    );
                  }}
                />
                <Icon name="info" className={styles.uploadInfo} />
                <div className={styles.uploadInfoText}>
                  ระบุว่าเป็นหัวหน้านายหน้า
                  <br />
                  ทำให้มีสิทธิ์ใช้งานในระบบ TQM Life
                  <br />
                  Platform เป็น "หัวหน้าพนักงานขาย"
                </div>
              </div>
            </div>
            <div className={styles.rowInterview}>
              <div
                className={cx(styles.columnInterview, styles.approvedInterview)}
              >
                <b>ชื่อทีม</b>
                <div className={styles.explain}>
                  {values.recruits?.lifeBroker?.teamName || "-"}
                </div>
              </div>
            </div>
            <div className={styles.rowInterview}>
              <div
                className={cx(styles.columnInterview, styles.approvedInterview)}
              >
                <b>แผนการตลาด</b>
                <div className={styles.explain}>
                  {values.recruits
                    ? (
                        options?.recruitmentOptions?.lifeBrokerOptions
                          ?.marketPlanOptions || []
                      ).find(
                        (marketPlan) =>
                          marketPlan.value ===
                          values?.recruits?.lifeBroker?.mkPlanId
                      )?.label
                    : "-"}
                </div>
              </div>
              <div
                className={cx(styles.columnInterview, styles.approvedInterview)}
              >
                <b>ระดับสายงาน</b>
                <div className={styles.explain}>
                  {values.recruits
                    ? (
                        options?.recruitmentOptions?.lifeBrokerOptions
                          ?.marketPlanOptions || []
                      )
                        .find(
                          (marketPlan) =>
                            marketPlan.value ===
                            values?.recruits?.lifeBroker?.mkPlanId
                        )
                        ?.positionLevelOptions.find(
                          (positionLevel) =>
                            positionLevel.value ===
                            values?.recruits?.lifeBroker?.positionLevel
                        )?.label
                    : "-"}
                </div>
              </div>
            </div>
            <div className={styles.rowInterview}>
              <div
                className={cx(styles.columnInterview, styles.approvedInterview)}
              >
                <b>อัปไลน์</b>
                <div className={styles.explain}>
                  {/* {values.recruits
                      ? (
                          options?.recruitmentOptions?.lifeBrokerOptions?.upline
                            ?.options || []
                        ).find(
                          (upline) =>
                            upline.value ===
                            values?.recruits?.lifeBroker?.uplineId
                        )?.label
                      : "-"} */}
                  {values.recruits
                    ? (
                        options?.recruitmentOptions?.lifeBrokerOptions
                          ?.marketPlanOptions || []
                      )
                        .find(
                          (marketPlanOption) =>
                            marketPlanOption.value ===
                            values.recruits.lifeBroker.mkPlanId
                        )
                        ?.positionLevelOptions.find(
                          (positionLevel) =>
                            positionLevel.value ===
                            values.recruits.lifeBroker.positionLevel
                        )
                        ?.uplineOptions.find(
                          (upline) =>
                            upline.value === values.recruits.lifeBroker.uplineId
                        )?.label
                    : "-"}
                </div>
              </div>
              <div
                className={cx(styles.columnInterview, styles.approvedInterview)}
              >
                <b>ผู้ชวน</b>
                <div className={styles.explain}>
                  {values.recruits?.lifeBroker?.inviteBy || "-"}
                </div>
              </div>
            </div>
          </>
        )}
        <div className={styles.rowInterview}>
          <div
            className={cx(
              styles.columnNoticeInterview,
              styles.approvedInterview
            )}
          >
            <b>หมายเหตุ</b>
            <div className={styles.explain}>
              {values.recruits?.lifeBroker?.description || "-"}
            </div>
          </div>
        </div>
        <div
          className={cx(
            styles.rowInterview,
            styles.rowAutorize,
            styles.displayAutorize
          )}
        >
          <div
            // className={styles.columnApprove}
            className={
              values.recruits?.lifeBroker?.recruitStatus === "failed"
                ? cx(styles.columnApprove, styles.columnRejectApprove)
                : styles.columnApprove
            }
          >
            <CheckboxAutorize
              name="recruits.lifeBroker.recommendNonLife"
              value={values.recruits?.lifeBroker?.recommendNonLife}
              label="สิทธิ์แนะนำหมวดประกันวินาศภัย"
              disabled={
                values.recruits?.lifeBroker?.recruitStatus === "expired"
              }
              onClick={(e) => {
                setFieldValue(
                  "recruits.lifeBroker.recommendNonLife",
                  e.target.checked
                );
              }}
            />
          </div>
          <b
            className={
              values.recruits?.lifeBroker?.recruitStatus === "success"
                ? styles.approvedText
                : values.recruits?.lifeBroker?.recruitStatus === "processing"
                ? styles.waitingText
                : values.recruits?.lifeBroker?.recruitStatus === "expired"
                ? {}
                : styles.rejectText
            }
          >
            {values.recruits?.lifeBroker?.recruitStatus === "success"
              ? "อนุมัติ"
              : values.recruits?.lifeBroker?.recruitStatus === "processing"
              ? "รออนุมัติ"
              : values.recruits?.lifeBroker?.recruitStatus === "expired"
              ? "หมดอายุ"
              : "ไม่อนุมัติ"}
          </b>
        </div>
      </div>
    );
  } else if (values?.recruits?.lifeBroker) {
    return (
      <div className={styles.newInterview}>
        <div className={styles.rowInterview}>
          <div className={styles.columnInterview}>
            <NewInterviewComponent
              label="ผลการสัมภาษณ์"
              role="นายหน้าประกันชีวิต"
              values={values.recruits?.lifeBroker?.interviewStatus}
              onChange={(e) => {
                setFieldValue("recruits.lifeBroker.interviewStatus", e);
              }}
            />
          </div>
          <div className={cx(styles.columnInterview)}>
            <div className={styles.activatedAt}>
              {`วันที่สมัคร : `}
              {values.recruits?.lifeBroker?.recruitedAt
                ? dateFormatterFromPattern(
                    values.recruits?.lifeBroker?.recruitedAt
                  )
                : "-"}
            </div>
            <Input
              label=""
              className={styles.customInput}
              name="recruits.lifeBroker.note"
              placeholder="โปรดระบุ"
            />
          </div>
        </div>
        <b className={styles.titleInfo}>กรุณาระบุข้อมูลด้านล่างให้ถูกต้อง</b>
        <div className={styles.rowInterview}>
          <div className={styles.columnInterview}>
            <div className={styles.textInput}>
              Staff Code <span>*</span>
            </div>
            <Input
              label=""
              className={
                errorRecruit?.staffCode
                  ? styles.recruitError
                  : styles.customInput
              }
              name="recruits.lifeBroker.staffCode"
              placeholder="โปรดระบุ"
              onChange={(val) => {
                handleChangeRecruit(val, "staffCode", "lifeBroker");
              }}
            />
            {errorRecruit?.staffCode && (
              <small className={styles.recruitErrorMessage}>
                {errorRecruit.staffCode}
              </small>
            )}
          </div>
          <div className={cx(styles.rowAutorize, styles.newRowAutorize)}>
            <CheckboxAutorize
              name="recruits.lifeBroker.isLeader"
              value={values.recruits?.lifeBroker?.isLeader}
              label="หัวหน้านายหน้า"
              onClick={(e) => {
                setFieldValue("recruits.lifeBroker.isLeader", e.target.checked);
              }}
            />
            <Icon name="info" className={styles.uploadInfo} />
            <div className={styles.uploadInfoText}>
              ระบุว่าเป็นหัวหน้านายหน้า
              <br />
              ทำให้มีสิทธิ์ใช้งานในระบบ TQM Life
              <br />
              Platform เป็น "หัวหน้าพนักงานขาย"
            </div>
          </div>
        </div>
        <div className={cx(styles.rowInterview)}>
          <div className={cx(styles.columnInterview)}>
            <div className={styles.textInput}>
              ชื่อทีม <span>*</span>
            </div>
            <Selector
              label=""
              name="recruits.lifeBroker.teamId"
              className={
                errorRecruit.teamId
                  ? styles.recruitSelectError
                  : styles.customInput
              }
              options={
                options?.recruitmentOptions?.lifeBrokerOptions?.teamOptions ||
                []
              }
              width="100%"
              placeholder="โปรดระบุ"
              isSearchable
              onChange={(val) => {
                handleChangeRecruit(val, "teamId", "lifeBroker");
              }}
            />
            {errorRecruit.teamId && (
              <small className={styles.recruitErrorMessage}>
                {errorRecruit.teamId}
              </small>
            )}
          </div>
        </div>
        <div className={styles.rowInterview}>
          <div className={cx(styles.columnInterview)}>
            <div className={styles.textInput}>
              แผนการตลาด <span>*</span>
            </div>
            <Selector
              label=""
              name="recruits.lifeBroker.mkPlanId"
              className={
                errorRecruit.mkPlanId
                  ? styles.recruitSelectError
                  : styles.customInput
              }
              options={
                options?.recruitmentOptions?.lifeBrokerOptions
                  ?.marketPlanOptions || []
              }
              width="100%"
              placeholder="โปรดระบุ"
              isSearchable
              onChange={(val) => {
                handleChangeRecruit(val, "mkPlanId", "lifeBroker");
              }}
            />
            {errorRecruit.mkPlanId && (
              <small className={styles.recruitErrorMessage}>
                {errorRecruit.mkPlanId}
              </small>
            )}
          </div>
          <div className={cx(styles.columnInterview)}>
            <div className={styles.textInput}>
              ระดับสายงาน <span>*</span>
            </div>
            <Selector
              label=""
              name="recruits.lifeBroker.positionLevel"
              className={
                errorRecruit.positionLevel
                  ? styles.recruitSelectError
                  : styles.customInput
              }
              options={
                options?.recruitmentOptions?.lifeBrokerOptions?.marketPlanOptions.find(
                  (marketPlanOption) =>
                    marketPlanOption.value ===
                    values.recruits.lifeBroker.mkPlanId
                )?.positionLevelOptions || []
              }
              disabled={["", null].includes(
                values?.recruits?.lifeBroker?.mkPlanId
              )}
              width="100%"
              placeholder="โปรดระบุ"
              onChange={(val) => {
                handleChangeRecruit(val, "positionLevel", "lifeBroker");
              }}
            />
            {errorRecruit.positionLevel && (
              <small className={styles.recruitErrorMessage}>
                {errorRecruit.positionLevel}
              </small>
            )}
          </div>
        </div>
        <div className={styles.rowInterview}>
          <div
            className={cx(styles.columnInterview, styles.columnUplineInterview)}
          >
            <div className={styles.textInput}>
              อัปไลน์
              {/* <span>*</span> */}
            </div>
            <Selector
              label=""
              name="recruits.lifeBroker.uplineId"
              className={
                errorRecruit.uplineId
                  ? styles.recruitSelectError
                  : styles.customInput
              }
              // options={
              //   options?.recruitmentOptions?.lifeBrokerOptions?.upline
              //     ?.options || []
              // }
              // disabled={
              //   ["", null].includes(
              //     values?.recruits?.lifeBroker?.positionLevel
              //   ) ||
              //   options?.recruitmentOptions?.lifeBrokerOptions?.upline?.options
              //     ?.length === 0
              // }
              options={
                options?.recruitmentOptions?.lifeBrokerOptions?.marketPlanOptions
                  ?.find(
                    (marketPlanOption) =>
                      marketPlanOption.value ===
                      values.recruits.lifeBroker.mkPlanId
                  )
                  ?.positionLevelOptions.find(
                    (positionLevel) =>
                      positionLevel.value ===
                      values.recruits.lifeBroker.positionLevel
                  )?.uplineOptions || []
              }
              disabled={
                ["", null].includes(
                  values?.recruits?.lifeBroker?.positionLevel
                ) ||
                options?.recruitmentOptions?.lifeBrokerOptions?.marketPlanOptions
                  ?.find(
                    (marketPlanOption) =>
                      marketPlanOption.value ===
                      values.recruits.lifeBroker.mkPlanId
                  )
                  ?.positionLevelOptions.find(
                    (positionLevel) =>
                      positionLevel.value ===
                      values.recruits.lifeBroker.positionLevel
                  )?.uplineOptions?.length === 0
              }
              width="100%"
              placeholder="โปรดระบุ"
              isSearchable
              onChange={(val) => {
                handleChangeRecruit(val, "uplineId", "lifeBroker");
              }}
            />
            {errorRecruit.uplineId && (
              <small className={styles.recruitErrorMessage}>
                {errorRecruit.uplineId}
              </small>
            )}
            <Icon name="info" className={styles.uploadInfo} />
            <div className={styles.uploadInfoText}>กรุณาระบุ Staff Code</div>
          </div>
          <div className={cx(styles.columnInterview)}>
            <div className={styles.textInput}>ผู้ชวน</div>
            <Input
              label=""
              className={styles.customInput}
              name="recruits.lifeBroker.inviteBy"
              placeholder="โปรดระบุ"
            />
          </div>
        </div>
        <div className={styles.rowInterview}>
          <div className={cx(styles.columnInterview, styles.columnNotice)}>
            <div className={styles.textInput}>หมายเหตุ</div>
            <Input
              label=""
              className={styles.customInput}
              name="recruits.lifeBroker.description"
              placeholder="เหตุผลที่อนุมัติ ไม่อนุมัติ"
              maxlength={200}
            />
          </div>
        </div>
        <div className={cx(styles.rowInterview, styles.rowAutorize)}>
          <CheckboxAutorize
            name="recruits.lifeBroker.recommendNonLife"
            value={values.recruits?.lifeBroker?.recommendNonLife}
            label="สิทธิ์แนะนำหมวดประกันวินาศภัย"
            onClick={(e) => {
              setFieldValue(
                "recruits.lifeBroker.recommendNonLife",
                e.target.checked
              );
            }}
          />
        </div>
        <div className={cx(styles.rowInterview, styles.rowApprove)}>
          <RejectEmail
            sendEmail={updateAgentType}
            options={options}
            name={"lifeBroker"}
            data={values}
          />
          <ApprovedEmail
            sendEmail={updateAgentType}
            role="นายหน้าประกันชีวิต"
            name={"lifeBroker"}
            data={values}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.newInterview}>
        <div className={styles.rowInterview}>
          <div className={{}}>
            <div>
              ผลการสัมภาษณ์ : <b>นายหน้าประกันชีวิต</b> จะแสดงเมื่อมีการสมัคร
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default LifeBrokerForm;
