import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import moment from "moment/moment";
import { markAsCheck, markAsRead } from "store/notificationSlice";

const enhancer = compose(
  withHooks((props) => {
    const dispatch = useDispatch();
    const { currentAdmin } = useSelector((state) => state.auth);
    const { notifications } = useSelector((state) => state.notification);

    useEffect(() => {
      if (notifications) {
        let notificatonIds = [];
        notifications.forEach((item) => {
          if (item.id && !item.isChecked) {
            notificatonIds.push(item.id);
          }
        });
        if (notificatonIds.length > 0) dispatch(markAsCheck(notificatonIds));
      }
    }, [notifications, dispatch]);

    const setOpenToRead = useCallback(
      async (notification) => {
        if (notifications) {
          let notificatonIds = [];
          notifications.forEach((item) => {
            if (item.id && !item.isRead && notification.id === item.id) {
              notificatonIds.push(item.id);
            }
          });
          if (notificatonIds.length > 0) {
            dispatch(markAsRead(notificatonIds));
          }
        }
        if (["recruitment", "document"].includes(notification.actionType))
          window.open(`/agents/${notification.agentId}`, "_self");
      },
      [notifications, dispatch]
    );

    const notificationToday = useMemo(() => {
      let newNotificatons = [];
      if (notifications) {
        notifications.forEach((item) => {
          if (moment(item?.alertAt).isSame(new Date(), "day")) {
            newNotificatons.push(item);
          }
        });
        newNotificatons = newNotificatons.sort(
          (a, b) =>
            new Date(b.alertAt).getTime() - new Date(a.alertAt).getTime()
        );
      }
      return newNotificatons;
    }, [notifications]);

    const notificationLated = useMemo(() => {
      let newNotificatons = [];
      if (notifications) {
        notifications.forEach((item) => {
          if (moment(item?.alertAt).isBefore(new Date(), "day")) {
            newNotificatons.push(item);
          }
        });
        newNotificatons = newNotificatons.sort(
          (a, b) =>
            new Date(b.alertAt).getTime() - new Date(a.alertAt).getTime()
        );
      }

      return newNotificatons;
    }, [notifications]);

    return {
      data: currentAdmin,
      notifications,
      notificationToday,
      notificationLated,
      setOpenToRead,
    };
  }),
  mapProps(
    ({
      data,
      notifications,
      notificationToday,
      notificationLated,
      setOpenToRead,
    }) => ({
      data,
      notifications,
      notificationToday,
      notificationLated,
      setOpenToRead,
    })
  )
);

export default enhancer;
