import { createSlice } from "@reduxjs/toolkit";
import api from "api";
import camelCase from "lodash/camelCase";
// const MOCKUP_ADMINS = Array.from(Array(10)).map((_, i) => ({
//   _id: i + 1,
//   agentCode: "650500",
//   adminName: "ประหยุด จันทร์โทรลา",
//   position: "ผู้ดูแลระบบ",
//   areaCode: "BKK",
//   branchCode: "TQM",
//   createdDate: new Date(),
//   status: "online",
//   updatedDate: new Date(),
// }));

// const MOCKUP_ADMIN = {
//   _id: 1,
//   avatar: "https://www.naewna.com/uploads/news/source/332046.jpg",
//   adminCode: "650500",
//   status: "ผู้นำประเทศ",
//   prefixName: "นาย",
//   firstName: "ประหยุด",
//   lastName: "จันทร์โทรลา",
//   prefixNameEn: "Mr.",
//   firstNameEn: "Prayud",
//   lastNameEn: "Jantrola",
//   phoneNumber: "0801125544",
//   email: "J.prayud@pprp.or.th",
//   adminType: "เจ้าหน้าที่สาขา",
//   activatedAt: new Date(),
//   areaCode: "BKK",
//   branchCode: "TQM",
//   permission: {
//     candidateManagement: ["responder"],
//     affiliateTeamManagement: ["CRU"],
//     affiliateLeadManagement: ["read_only"],
//     announcement: ["CRU"],
//     adminManagement: ["CRU"],
//     consentPolicyManagement: ["CRU"],
//     dashboard: ["access"],
//     commissionReport: ["manage"],
//   },
// };

// const MOCKUP_OPTIONS = {
//   prefixNameOptions: [
//     { label: "นาย", value: 1, th: "นาย", en: "Mr." },
//     { label: "นาง", value: 2, th: "นาง", en: "Mrs." },
//     { label: "นางสาว", value: 3, th: "นางสาว", en: "Miss" },
//   ],
//   statusOptions: [
//     { label: "ปกติ", value: "online" },
//     { label: "ไม่ปกติเท่าไหร่", value: "abnormal" },
//     { label: "นายบ้าไม่แล้วงั้นรึ", value: "RUmad?" },
//   ],
//   positionOptions: [{ label: "ต้าวหน้าที่สาขา", value: "brancher" }],
//   areaOptions: [{ label: "กรุงเทพ", value: "BKK" }],
//   branchOptions: [
//     { label: "TQM", value: "TQM" },
//     { label: "TQN", value: "TQN" },
//     { label: "TQO", value: "TQO" },
//     { label: "TQP", value: "TQP" },
//   ],
// };

const adminInitialState = {
  isLoading: false,
  admins: [],
  paging: {
    page: 1,
    perPage: 10,
  },
  admin: {},
  options: {},
};

const adminReducers = {
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setAdmins(state, action) {
    state.admins = action.payload.admins;
    state.paging = action.payload.paging;
  },
  setAdmin(state, action) {
    state.admin = action.payload;
  },
  setOptions(state, action) {
    state.options.adminTypeOptions = action.payload.adminTypeOptions;
    state.options.branchOptions = action.payload.branchOptions;
    state.options.areaOptions = action.payload.areaOptions;
    state.options.permissionOptions = action.payload.permissionOptions;
    state.options.prefixNameEnOptions = action.payload.prefixNameEnOptions;
    state.options.prefixNameThOptions = action.payload.prefixNameThOptions;
    state.options.statusOptions = action.payload.statusOptions;
    state.options.notificationPermissionOptions =
      action.payload.notificationPermissionOptions;
    let adminPermissions = [];
    let options = action.payload.permissionOptions;
    options.forEach((option) => {
      adminPermissions[camelCase(option.key)] = {
        name: option.label,
        permissions: option.values,
      };
    });
    state.options.adminPermissions = adminPermissions;
  },
};

export const adminSlice = createSlice({
  name: "admin",
  initialState: adminInitialState,
  reducers: adminReducers,
});

export const {
  setLoading,
  setAdmins,
  setAdmin,
  setOptions,
  setHistories,
} = adminSlice.actions;

export default adminSlice.reducer;

export const getAdmins = (values) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/admins", values);
    dispatch(setAdmins(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAdminById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/admins/${id}`);
    let data = response.data.data.admin;
    let newPermission = {
      candidateManagement: data?.candidateManagement || [],
      affiliateTeamManagement: data?.affiliateTeamManagement || [],
      affiliateManagement: data?.affiliateManagement || [],
      affiliateMkPlanManagement: data?.affiliateMkPlanManagement || [],
      adminManagement: data?.adminManagement || [],
      consentPolicyManagement: data?.consentPolicyManagement || [],
      announcement: data?.announcement || [],
      dashboard: data?.dashboard || [],
      commissionReport: data?.commissionReport || [],
    };
    let modifiedData = { ...data, permission: newPermission };
    dispatch(setAdmin(modifiedData));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAdminOptions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get("/admins/options");
    dispatch(setOptions(response.data.data));
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createAdmin = (values, cb = () => {}) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await api.post("/admins", values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const sendConfirmEmailById = (id, cb = () => {}) => async () => {
  try {
    await api.get(`/admins/${id}/send_confirmation`);
    cb();
  } catch (error) {
    console.error(error.response || error);
  }
};

export const sendUpdateEmailById = (id, cb = () => {}) => async () => {
  try {
    await api.get(`/admins/${id}/send_email_admin_type`);
    cb();
  } catch (error) {
    console.error(error.response || error);
  }
};

export const editAdminById = (id, values, cb = () => {}) => async (
  dispatch
) => {
  try {
    dispatch(setLoading(true));
    await api.put(`/admins/${id}`, values);
    cb();
  } catch (error) {
    console.error(error.response || error);
  } finally {
    dispatch(setLoading(false));
  }
};
