import React from "react";
import cx from "classnames";
import { Formik } from "formik";

import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";

import styles from "./FilterBar.module.css";
import initialValues from "./FilterBar.utils";

function FilterBar({ isOpen, options = {}, onChange = () => {} }) {
  return (
    <Formik initialValues={initialValues}>
      {(props) => {
        const { resetForm } = props;
        return (
          <form className={cx(styles.filterWrapper, !isOpen ? "hidden" : null)}>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="agreementType">ประเภท</label>
              <Selector
                width="100%"
                name="agreementType"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) =>
                  onChange({ agreementType: value, page: 1 })
                }
                options={options.agreementTypeOptions}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="contentDisplay">ส่วนแสดงเนื้อหา</label>
              <Selector
                width="100%"
                name="contentDisplay"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) =>
                  onChange({ contentDisplay: value, page: 1 })
                }
                options={options.contentDisplayOptions}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="version">เวอร์ชัน</label>
              <Input
                name="version"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                useTimeout={1500}
                onChange={(value) => onChange({ version: value, page: 1 })}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="status">สถานะ</label>
              <Selector
                width="100%"
                name="status"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) => onChange({ status: value, page: 1 })}
                options={options.statusOptions}
              />
            </div>
            <div className={styles.filterInputWrapper}>
              <label htmlFor="consentBy">ผู้ให้การยินยอม</label>
              <Selector
                width="100%"
                name="consentBy"
                className={styles.filterInput}
                placeholder="โปรดระบุ"
                onChange={(value) => onChange({ consentBy: value, page: 1 })}
                options={options.consentByOptions}
              />
            </div>
            <div
              className={cx(styles.filterInput, styles.reset)}
              onClick={() => {
                resetForm();
                onChange(initialValues);
              }}
            >
              รีเซ็ตค่าตัวเลือก
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FilterBar;
