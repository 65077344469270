import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import FilterBar from "./FilterBar";
import Table from "components/Table";

import enhancer from "./Team.compose";
import styles from "./Team.module.css";
import authorize, { UnauthorizedAction } from "utils/authorize";

function Team({
  data = [],
  options = {},
  isShowFilterBar,
  toggle,
  paging = {},
  searchValue = {},
  setSearchValue,
  onChange = () => {},
  disabled,
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "ชื่อทีม",
        accessor: "name", // accessor is the "key" in the data
        styles: {
          header: {
            width: "170px",
          },
        },
        Cell: (cell) => {
          return <Link to={`/team/${cell.row.original.id}`}>{cell.value}</Link>;
        },
      },
      {
        Header: "หัวหน้าทีมผู้แนะนำ",
        accessor: "affiliateLeader",
        styles: {
          header: {
            width: "220px",
          },
        },
      },
      {
        Header: "รหัสพื้นที่",
        accessor: "area",
        styles: {
          header: {
            width: "120px",
          },
        },
      },
      {
        Header: "รหัสสาขา",
        accessor: "branchCode",
        styles: {
          header: {
            width: "120px",
          },
        },
      },
      {
        Header: "ทีมหลัก TQM",
        accessor: "tqmTeam",
        styles: {
          header: {
            width: "160px",
          },
        },
        Cell: (cell) => {
          return cell.row.original?.tqmTeam || "-";
        },
      },
      {
        Header: "หัวหน้าทีมหลัก TQM",
        accessor: "tqmTeamLeader",
        styles: {
          header: {
            width: "240px",
          },
        },
        Cell: (cell) => {
          return cell.row.original?.tqmTeamLeader || "-";
        },
      },
      {
        Header: "สมาชิก",
        accessor: "totalMemberCount",
        styles: {
          header: {
            width: "100px",
            textAlign: "center",
          },
        },
      },
    ],
    []
  );

  return (
    <Container className={styles.container}>
      <section className={styles.header}>
        <h4 className="topic">จัดการทีมผู้แนะนำ</h4>
        <div className={styles.breadcrumb}>
          <Breadcrumb links={[{ label: "จัดการทีมผู้แนะนำ", current: true }]} />
          <div className={cx(styles.groupBtn)}>
            <Link to="/team/new">
              <button className={styles.btn} type="button" disabled={disabled}>
                + สร้าง
              </button>
            </Link>
            <button
              className={cx(styles.selectBtn, "secondary")}
              type="button"
              onClick={toggle}
            >
              ปรับตัวเลือก
            </button>
          </div>
        </div>
      </section>
      <FilterBar
        isOpen={isShowFilterBar}
        options={options}
        onChange={onChange}
      />
      <section className={styles.table}>
        <Table
          data={data}
          columns={columns}
          paging={paging}
          searchValue={searchValue}
          onChange={setSearchValue}
        />
      </section>
    </Container>
  );
}

export default authorize(enhancer(Team), {
  roles: "team",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["add"],
});
