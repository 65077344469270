import Container from "components/Layout/Container";

import enhancer from "./Notification.compose";
import styles from "./Notification.module.css";
import Icon from "components/Icon";
import moment from "moment/moment";

function Notification({ notificationToday, notificationLated, setOpenToRead }) {
  return (
    <Container className={styles.container}>
      <h4>การแจ้งเตือน</h4>
      {notificationToday.length > 0 && (
        <div>
          <p>ใหม่</p>
          <ul>
            {notificationToday.map((item, index) => (
              <li
                key={index}
                className={item.isRead ? styles.isRead : {}}
                onClick={() => {
                  setOpenToRead(item);
                }}
              >
                <div className={styles.detail}>
                  <div>
                    <Icon name="info-noti" />
                    <p>
                      <b>{item.actionName}</b> {item.agentName}{" "}
                      {item.actionType === "recruitment" && (
                        <>
                          <b>{item.subActionType}</b>{" "}
                          โปรดตรวจสอบข้อมูลของผู้สมัคร
                        </>
                      )}
                      {item.actionType === "document" && (
                        <>
                          มีการอัปโหลดเอกสารเพิ่มเติม
                          โปรดตรวจสอบข้อมูลของผู้สมัคร
                        </>
                      )}
                    </p>
                  </div>
                  <p>{moment(item.alertAt).locale("th").fromNow()}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {notificationLated.length > 0 && (
        <div>
          <p>ก่อนหน้านี้</p>
          <ul>
            {notificationLated.map((item, index) => (
              <li
                key={index}
                className={item.isRead ? styles.isRead : {}}
                onClick={() => {
                  setOpenToRead(item);
                }}
              >
                <div className={styles.detail}>
                  <div>
                    <Icon name="info-noti" />
                    <p>
                      <b>{item.actionName}</b> {item.agentName}{" "}
                      {item.actionType === "recruitment" && (
                        <>
                          <b>{item.subActionType}</b>{" "}
                          โปรดตรวจสอบข้อมูลของผู้สมัคร
                        </>
                      )}
                      {item.actionType === "document" && (
                        <>
                          มีการอัปโหลดเอกสารเพิ่มเติม
                          โปรดตรวจสอบข้อมูลของผู้สมัคร
                        </>
                      )}
                    </p>
                  </div>
                  <p>{moment(item.alertAt).locale("th").fromNow()}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Container>
  );
}

export default enhancer(Notification);
