import { Formik, FieldArray } from "formik";
import cx from "classnames";
import merge from "lodash/merge";
import isFuture from "date-fns/isFuture";
import Icon from "components/Icon";

import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";
import Textarea from "components/Form/Element/Textarea";
import DatePicker from "components/Form/Element/DatePicker";
import Modal from "components/Modal";

import { DEFAULT_VALUES } from "./AffiliateForm.utils";
import enhancer from "./AffiliateForm.compose";
import styles from "./AffiliateForm.module.css";

function AffiliateForm({
  id = "",
  options = {
    affiliateStatusOptions: [],
    marketPlanOptions: [],
    uplineOptions: [],
  },
  className = "",
  initialValues = {},
  onSubmit = () => {},
  showModal = false,
  handleCloseModal = () => {},
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={merge({}, DEFAULT_VALUES, initialValues)}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        const countingNote = values.description ? values.description.length : 0;

        const addNewPlan = () => {
          setFieldValue("affiliateCommissionPlans", [
            ...values.affiliateCommissionPlans,
            {
              beginDate: null,
              mkPlanId: "",
              positionLevel: "",
              uplineId: "",
            },
          ]);
        };

        return (
          <form
            id={id}
            onSubmit={handleSubmit}
            className={cx(styles.container, className)}
          >
            <Modal
              isOpen={showModal}
              onSubmit={() => {
                handleCloseModal();
                setFieldValue("emailType", "notification");
                handleSubmit();
              }}
              onCancel={() => {
                handleCloseModal();
                setFieldValue("emailType", "");
                handleSubmit();
              }}
              submitText={"ส่งอีเมล"}
              cancelText={"ไม่ส่งอีเมล"}
              contentLabel="Resend email affiliate Modal"
            >
              <p>
                ต้องการส่งอีเมลแจ้ง<b>การเปลี่ยนแปลงสถานะ</b>
                <br />
                และ <b>แผนการตลาด</b> หรือไม่ ?
              </p>
            </Modal>
            <img
              className={styles.avatar}
              src={values.avatar}
              alt="Affiliate avatar"
            />
            <section className={styles.fillContainer}>
              <Input name="firstName" label="ชื่อจริง" disabled />
              <Input name="lastName" label="นามสกุล" disabled />
              <Input
                name="teamName"
                label="ทีมผู้แนะนำที่สังกัด"
                placeholder="โปรดระบุ"
                disabled
              />
              <Input
                name="tqmTeamName"
                label="ทีมหลัก TQM"
                placeholder="โปรดระบุ"
                disabled
              />
              <Selector
                name="status"
                label="สถานะ"
                options={options.affiliateStatusOptions || []}
                width="100%"
              />
              <div className={styles.note}>
                <Textarea name="description" label="หมายเหตุ" />
                <small className={styles.count}>{countingNote}/400</small>
              </div>
            </section>
            <section className={styles.plan}>
              <div className={styles.planHeader}>
                <b className="topic">แผนการตลาดทีมผู้แนะนำ</b>
                <button
                  type="button"
                  className={cx("secondary", styles.addBtn)}
                  onClick={addNewPlan}
                >
                  เพิ่มแผน
                </button>
              </div>
              <FieldArray
                name="affiliateCommissionPlans"
                render={(arrayHelpers) => {
                  const onTrash = (index) => arrayHelpers.remove(index);

                  return ((values || {}).affiliateCommissionPlans || []).map(
                    (p, index) => {
                      return (
                        <section key={index} className={styles.planContainer}>
                          <div
                            className={cx(
                              styles.fillContainer,
                              styles.planFillContainer
                            )}
                          >
                            <button
                              type="button"
                              className={cx(styles.delete, "secondary")}
                              onClick={() => onTrash(index)}
                              disabled={p.beginDate && !isFuture(p.beginDate)}
                            >
                              <Icon name="bin" />
                            </button>
                            <Selector
                              name={`affiliateCommissionPlans.${index}.mkPlanId`}
                              label="ชื่อแผน"
                              options={options.marketPlanOptions || []}
                              width="100%"
                              disabled={p.beginDate && !isFuture(p.beginDate)}
                              required
                              isSearchable
                              onChange={(e) => {
                                let beginDate = options?.marketPlanOptions.find(
                                  (marketPlan) => marketPlan.value === e
                                )?.minBeginDate
                                  ? new Date(
                                      options.marketPlanOptions.find(
                                        (marketPlan) => marketPlan.value === e
                                      ).minBeginDate
                                    )
                                  : new Date();
                                setFieldValue(
                                  `affiliateCommissionPlans.${index}.beginDate`,
                                  beginDate
                                );
                              }}
                            />
                            <DatePicker
                              name={`affiliateCommissionPlans.${index}.beginDate`}
                              label="วันที่มีผลบังคับ"
                              minDate={
                                new Date(
                                  options?.marketPlanOptions.find(
                                    (marketPlanOption) =>
                                      marketPlanOption.value ===
                                      values.affiliateCommissionPlans[index]
                                        .mkPlanId
                                  )?.minBeginDate
                                )
                              }
                              disabled={
                                (p.beginDate && !isFuture(p.beginDate)) ||
                                values.affiliateCommissionPlans[index]
                                  .mkPlanId === ""
                              }
                              required
                            />
                            <Selector
                              name={`affiliateCommissionPlans.${index}.positionLevel`}
                              label="ตำแหน่ง"
                              options={
                                values.affiliateCommissionPlans[index].mkPlanId
                                  ? options?.marketPlanOptions.find(
                                      (marketPlanOption) =>
                                        marketPlanOption.value ===
                                        values.affiliateCommissionPlans[index]
                                          .mkPlanId
                                    )?.positionLevel.options || []
                                  : []
                              }
                              width="100%"
                              disabled={
                                (p.beginDate && !isFuture(p.beginDate)) ||
                                values.affiliateCommissionPlans[index]
                                  .mkPlanId === ""
                              }
                              required
                              isSearchable
                            />
                            <Selector
                              name={`affiliateCommissionPlans.${index}.uplineId`}
                              label="อัปไลน์"
                              options={
                                options.uplineOptions.filter(
                                  (upline) =>
                                    upline.label !==
                                    `${values.agentKey} - ${values.name}`
                                ) || []
                              }
                              disabled={p.beginDate && !isFuture(p.beginDate)}
                              width="100%"
                              isSearchable
                            />
                          </div>
                          {/* <Upline
                            data={p?.uplineTreePlan || []}
                            initialOpen={index === 0}
                          /> */}
                        </section>
                      );
                    }
                  );
                }}
              />
            </section>
          </form>
        );
      }}
    </Formik>
  );
}

export default enhancer(AffiliateForm);
