import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";
import { logout } from "store/authSlice";
import { useState } from "react";
import {
  getNotifications,
  markAsCheck,
  markAsRead,
} from "store/notificationSlice";
import { useEffect } from "react";
import { useRef } from "react";
import moment from "moment";
import { useMemo } from "react";
import session from "utils/session";

const enhancer = compose(
  withHooks(() => {
    // const history = useHistory();
    const dispatch = useDispatch();
    const { currentAdmin } = useSelector((state) => state.auth);
    const { notifications } = useSelector((state) => state.notification);
    const [status] = useState(!session.isAuthTokenPresent());
    const wrapperRef = useRef(null);

    const [isNotice, setIsNotice] = useState(false);

    const onLogout = useCallback(() => {
      // if (window && window.confirm("ต้องการออกจากระบบหรือไม่ ?")) {
      //   dispatch(logout());
      // }
      dispatch(logout());
    }, [dispatch]);

    useEffect(() => {
      !status && dispatch(getNotifications());
      const interval = !status
        ? setInterval(() => {
            dispatch(getNotifications());
          }, 60000)
        : null;
      if (interval) {
        return () => clearInterval(interval);
      }
    }, [status, dispatch]);

    useEffect(() => {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setIsNotice(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [wrapperRef]);

    const setOpenChecked = useCallback(async () => {
      setIsNotice(true);
      if (notifications) {
        let notificatonIds = [];
        notifications.forEach((item) => {
          if (item.id && !item.isChecked) {
            notificatonIds.push(item.id);
          }
        });
        // console.log("notificatonIds", notificatonIds);
        if (notificatonIds.length > 0) {
          dispatch(markAsCheck(notificatonIds));
          dispatch(getNotifications());
        }
      }
    }, [notifications, dispatch]);

    const setOpenToRead = useCallback(
      async (notification) => {
        if (notifications) {
          let notificatonIds = [];
          notifications.forEach((item) => {
            if (item.id && !item.isRead && notification.id === item.id) {
              notificatonIds.push(item.id);
            }
          });
          if (notificatonIds.length > 0) {
            dispatch(markAsRead(notificatonIds));
          }
        }
        if (["recruitment", "document"].includes(notification.actionType))
          window.open(`/agents/${notification.agentId}`, "_self");
      },
      [notifications, dispatch]
    );

    const notificationToday = useMemo(() => {
      let newNotificatons = [];
      if (notifications) {
        notifications.forEach((item) => {
          if (moment(item?.alertAt).isSame(new Date(), "day")) {
            newNotificatons.push(item);
          }
        });
        newNotificatons = newNotificatons.sort(
          (a, b) =>
            new Date(b.alertAt).getTime() - new Date(a.alertAt).getTime()
        );
      }
      return newNotificatons;
    }, [notifications]);

    const notificationLated = useMemo(() => {
      let newNotificatons = [];
      if (notifications) {
        notifications.forEach((item) => {
          if (moment(item?.alertAt).isBefore(new Date(), "day")) {
            newNotificatons.push(item);
          }
        });
        newNotificatons = newNotificatons.sort(
          (a, b) =>
            new Date(b.alertAt).getTime() - new Date(a.alertAt).getTime()
        );
      }

      return newNotificatons;
    }, [notifications]);

    const haveIsChecked = useMemo(() => {
      let haveIsChecked = false;
      if (notifications) {
        notifications.forEach((item) => {
          if (!item.isChecked) {
            haveIsChecked = true;
          }
        });
      }
      return haveIsChecked;
    }, [notifications]);

    // useEffect(() => {
    //   console.log(notifications);
    // }, [notifications]);

    // useEffect(() => {
    //   console.log("notificationToday", notificationToday);
    // }, [notificationToday]);

    // useEffect(() => {
    //   console.log("notificationLated", notificationLated);
    // }, [notificationLated]);

    // useEffect(() => {
    //   console.log("haveIsChecked", haveIsChecked);
    // }, [haveIsChecked]);

    return {
      data: currentAdmin,
      onLogout,
      isNotice,
      setIsNotice,
      notifications,
      notificationToday,
      notificationLated,
      wrapperRef,
      haveIsChecked,
      setOpenChecked,
      setOpenToRead,
    };
  }),
  mapProps(
    ({
      data,
      onLogout,
      isNotice,
      setIsNotice,
      notifications,
      notificationToday,
      notificationLated,
      wrapperRef,
      haveIsChecked,
      setOpenChecked,
      setOpenToRead,
    }) => ({
      data,
      onLogout,
      isNotice,
      setIsNotice,
      notifications,
      notificationToday,
      notificationLated,
      wrapperRef,
      haveIsChecked,
      setOpenChecked,
      setOpenToRead,
    })
  )
);

export default enhancer;
