import Table from "components/Table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { currency, dateFormatterFromPattern } from "utils/formatter";
import styles from "./CommissionTable.module.css";

function CommissionTable({ data = [], options = [], onChange, paging }) {
  const columns = useMemo(
    () => [
      {
        Header: "รหัสโอกาสในการขาย",
        accessor: "opportunityKey",
        styles: {
          header: {
            width: "200px",
          },
        },
        Cell: (cell) => {
          return (
            <Link to={`/commission/${cell.row.original.id}`}>{cell.value}</Link>
          );
        },
      },
      {
        Header: "รหัสคำสั่งซื้อ",
        accessor: "orderNo",
        styles: {
          header: {
            width: "150px",
          },
        },
      },
      {
        Header: "เบี้ยประกัน",
        accessor: "premium",
        styles: {
          header: {
            width: "150px",
          },
        },
        Cell: (cell) => {
          return currency(cell.row.original.premium, 2, "");
        },
      },
      {
        Header: "ทีมผู้แนะนำ",
        accessor: "affiliateTeam",
        styles: {
          header: {
            width: "150px",
          },
        },
        Cell: (cell) => {
          if (cell.row.original.affiliateTeam)
            return cell.row.original.affiliateTeam;
          else return "-";
        },
      },
      {
        Header: "รหัสผู้แนะนำ",
        accessor: "agentCode",
        styles: {
          header: {
            width: "150px",
          },
        },
      },
      {
        Header: "ชื่อผู้แนะนำ",
        accessor: "affiliateName",
        styles: {
          header: {
            width: "280px",
          },
        },
      },
      {
        Header: "ยอดจ่าย",
        accessor: "commissionAmount",
        styles: {
          header: {
            width: "150px",
          },
          cell: {
            textAlign: "right",
          },
        },
        Cell: (cell) => {
          let commissionAmount = parseFloat(cell.row.original.commissionAmount);
          if (commissionAmount > 0) {
            return <b>{currency(commissionAmount, 2, "")}</b>;
          } else {
            return currency(commissionAmount, 2, "");
          }
        },
      },
      {
        Header: "เรียกคืน",
        accessor: "reclaimAmount",
        styles: {
          header: {
            width: "150px",
          },
          cell: {
            textAlign: "right",
          },
        },
        Cell: (cell) => {
          let reclaimAmount = parseFloat(cell.row.original.reclaimAmount);
          if (reclaimAmount > 0) {
            return <b>{currency(reclaimAmount, 2, "")}</b>;
          } else {
            return currency(reclaimAmount, 2, "");
          }
        },
      },
      {
        Header: "วันที่ได้รับเงิน",
        accessor: "receivedDate",
        styles: {
          header: {
            width: "150px",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            dateFormatterFromPattern(cell.row.original.receivedDate) || "-"
          );
        },
      },
      {
        Header: "วันที่แจ้งแนะนำ",
        accessor: "affiliateSubmittedAt",
        styles: {
          header: {
            width: "150px",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return (
            dateFormatterFromPattern(cell.row.original.affiliateSubmittedAt) ||
            "-"
          );
        },
      },
      {
        Header: "วันที่แจ้งงานขาย",
        accessor: "submittedAt",
        styles: {
          header: {
            width: "150px",
          },
          cell: {
            textAlign: "center",
          },
        },
        Cell: (cell) => {
          return dateFormatterFromPattern(cell.row.original.submittedAt) || "-";
        },
      },
      {
        Header: "บริษัทประกัน",
        accessor: "companyName",
        styles: {
          header: {
            width: "150px",
          },
        },
      },
      {
        Header: "แบบประกัน",
        accessor: "orderName",
        styles: {
          header: {
            width: "150px",
          },
        },
      },
      {
        Header: "ชื่อลูกค้า",
        accessor: "contactName",
        styles: {
          header: {
            width: "280px",
          },
        },
      },
      {
        Header: "เลขกรมธรรม์",
        accessor: "policyNo",
        styles: {
          header: {
            width: "150px",
          },
        },
        Cell: (cell) => {
          if (cell.row.original.policyNo) return cell.row.original.policyNo;
          else return "-";
        },
      },
      {
        Header: "สถานะกรมธรรม์",
        accessor: "policyState",
        styles: {
          header: {
            width: "180px",
          },
        },
        Cell: (cell) => {
          if (cell.row.original.policyState)
            return cell.row.original.policyState;
          else return "-";
        },
      },
      {
        Header: "ปีกรมฯ",
        accessor: "policyYear",
        styles: {
          header: {
            width: "90px",
          },
        },
      },
      {
        Header: "งวด",
        accessor: "installmentNo",
        styles: {
          header: {
            width: "80px",
          },
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <section className={styles.container}>
      <Table
        columns={columns}
        data={data}
        paging={paging}
        onChange={onChange}
      />
    </section>
  );
}

export default CommissionTable;
