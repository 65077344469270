import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import useToggle from "hooks/useToggle";
import { getAdmins, getAdminOptions } from "store/adminSlice";

const enhancer = compose(
  withHooks((props) => {
    const [isShowFilterBar, toggle] = useToggle();
    const [searchValue, setSearchValue] = useState({
      adminCode: "",
      name: "",
      status: "",
      adminType: "",
      areaCode: "",
      branchCode: "",
      orderBy: "",
      order: "desc",
      page: 1,
      perPage: 10,
    });
    const dispatch = useDispatch();
    const { admins = [], options = {}, isLoading, paging = {} } = useSelector(
      (state) => state.admin
    );

    useEffect(() => {
      dispatch(getAdmins(searchValue));
    }, [dispatch, searchValue]);

    useEffect(() => {
      dispatch(getAdminOptions());
    }, [dispatch]);

    const onFilterChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    const onPaginateChange = useCallback(
      (value) => {
        setSearchValue({ ...searchValue, ...value });
      },
      [searchValue, setSearchValue]
    );

    return {
      data: admins,
      paging,
      options,
      isLoading,
      isShowFilterBar,
      toggle,
      onFilterChange,
      onChange: onPaginateChange,
      searchValue,
    };
  }),
  mapProps(
    ({
      data,
      paging,
      options,
      isLoading,
      isShowFilterBar,
      toggle,
      onFilterChange,
      onChange,
      searchValue,
      disabled = false,
    }) => ({
      data,
      paging,
      options,
      isLoading,
      isShowFilterBar,
      toggle,
      onFilterChange,
      onChange,
      searchValue,
      disabled,
    })
  )
);

export default enhancer;
