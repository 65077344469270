import styles from "./Documents.module.css";
import Icon from "components/Icon";
import { dateFormatterFromPattern } from "utils/formatter";
import { Fragment } from "react";

const Documents = ({
  data,
  getFileDocument = () => {},
  handleOpen = () => {},
}) => {
  return (
    <div className={styles.documents}>
      {data?.explain && (
        <div className={styles.insertExplain}>
          <div className={styles.title}>
            <label>ชี้แจงสำคัญ</label>
            <Icon name="info" className={styles.iconInfo} />
          </div>
          <div className={styles.explain}>{data.explain}</div>
        </div>
      )}
      {data?.archiveDocuments?.length > 0 && (
        <div className={styles.archiveButton}>
          <button type="button" onClick={handleOpen}>
            เอกสารที่เคยยื่นสมัคร
          </button>
        </div>
      )}
      <div className={styles.agentDocuments}>
        <div className={styles.title}>
          <b>เอกสารการสมัคร</b>
        </div>
        <div className={styles.header}>
          <div style={{ paddingLeft: 16 }}>เอกสาร</div>
          <div>สถานะ</div>
          <div>วันอัปเดต</div>
          <div>คำแนะนำ</div>
        </div>
        {data.agentDocuments.map((agentDocument, index) => (
          <div key={index} className={styles.row}>
            <div className={styles.name}>
              <Icon
                name="upload"
                className={
                  agentDocument?.documentImageId
                    ? styles.iconUpload
                    : styles.iconUploadDisabled
                }
              />
              <label
                style={
                  agentDocument?.documentImageId
                    ? { color: "var(--agt-primary-color)" }
                    : {
                        color: "var(--agt-secondary-color-1)",
                        cursor: "not-allowed",
                      }
                }
                onClick={() => {
                  if (agentDocument?.documentImageId)
                    getFileDocument(
                      {
                        type: "document",
                        downloadId: agentDocument?.id,
                      },
                      agentDocument?.name
                    );
                }}
              >
                {agentDocument?.name || ""}
              </label>
            </div>
            <div>{agentDocument?.statusTh || "-"}</div>
            <div>
              {agentDocument?.updatedAt
                ? dateFormatterFromPattern(agentDocument.updatedAt)
                : "-"}
            </div>
            <div>{agentDocument?.description || "-"}</div>
          </div>
        ))}
      </div>
      <div className={styles.agentImportant}>
        <div className={styles.title}>
          <b>สำเนาเอกสารสำคัญ</b>
        </div>
        {data.agentImportantDocuments.map((agentImportantDocument, index) => {
          return (
            <div key={index} className={styles.row}>
              <div className={styles.name}>
                <Icon
                  name="upload"
                  className={
                    agentImportantDocument?.importantDocumentImageId
                      ? styles.iconUpload
                      : styles.iconUploadDisabled
                  }
                />
                <label
                  style={
                    agentImportantDocument?.importantDocumentImageId
                      ? { color: "var(--agt-primary-color)" }
                      : {
                          color: "var(--agt-secondary-color-1)",
                          cursor: "not-allowed",
                        }
                  }
                  onClick={() => {
                    if (agentImportantDocument?.importantDocumentImageId)
                      getFileDocument(
                        {
                          type: "important_document",
                          downloadId: agentImportantDocument?.id,
                        },
                        agentImportantDocument?.name
                      );
                  }}
                >
                  {agentImportantDocument?.name}
                </label>
              </div>
              <div>{agentImportantDocument?.statusTh || "-"}</div>
              <div>
                {agentImportantDocument?.updatedAt
                  ? dateFormatterFromPattern(agentImportantDocument.updatedAt)
                  : "-"}
              </div>
              <div>{agentImportantDocument?.description || "-"}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.agentLicense}>
        <div className={styles.title}>
          <b>เลขที่ใบอนุญาต</b>
        </div>
        <div className={styles.header}>
          <div style={{ paddingLeft: 16 }}>เลขที่</div>
          <div>ประเภทใบอนุญาต</div>
          <div>สถานะ</div>
          <div>วันหมดอายุ</div>
          <div>วันที่อัปเดต</div>
        </div>
        {data.agentLicenses.map((agentLicense, index) => {
          return (
            <Fragment key={index}>
              <div className={styles.row}>
                <div style={{ paddingLeft: 16 }} className={styles.name}>
                  <Icon
                    name="upload"
                    className={
                      agentLicense.licenseCode
                        ? styles.iconUpload
                        : styles.iconUploadDisabled
                    }
                  />
                  <label
                    style={
                      agentLicense?.licenseCode
                        ? { color: "var(--agt-primary-color)" }
                        : {
                            color: "var(--agt-secondary-color-1)",
                            cursor: "not-allowed",
                          }
                    }
                    onClick={() => {
                      if (agentLicense?.licenseCode)
                        getFileDocument(
                          {
                            type: "agent_license",
                            downloadId: agentLicense?.id,
                          },
                          agentLicense?.licenseCode
                        );
                    }}
                  >
                    {agentLicense?.licenseCode || "ยังไม่ระบุ"}
                  </label>
                </div>
                <div>{agentLicense?.licenseType}</div>
                <div>{agentLicense?.statusTh || "-"}</div>
                <div>
                  {agentLicense?.licenseExpiredAt
                    ? dateFormatterFromPattern(agentLicense.licenseExpiredAt) ||
                      "-"
                    : "ยังไม่ระบุ"}
                </div>
                <div>
                  {agentLicense?.updatedAt
                    ? dateFormatterFromPattern(agentLicense.updatedAt)
                    : "-"}
                </div>
              </div>
              <div className={styles.subrow}>
                <div />
                <div>
                  <label className={styles.description}>คำแนะนำ</label>
                  <label className={styles.nowrap}>
                    {agentLicense?.description || "-"}
                  </label>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Documents;
