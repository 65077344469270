import { Link } from "react-router-dom";

import Container from "components/Layout/Container";
import Breadcrumb from "components/Breadcrumb";
import DisplayValue from "components/DisplayValue";

import enhancer from "./TeamId.compose";
import styles from "./TeamId.module.css";
import containerStyles from "components/Layout/Container/Container.module.css";
import Table from "components/Table";
import { dateFormatterFromPattern } from "utils/formatter";
import authorize, { UnauthorizedAction } from "utils/authorize";
import Modal from "components/Modal";
import useToggle from "hooks/useToggle";

const columns = [
  {
    Header: "รหัสผู้แนะนำ",
    accessor: "agentKey", // accessor is the "key" in the data
    styles: {
      header: {
        width: "150px",
      },
    },
  },
  {
    Header: "รหัสพนักงาน",
    accessor: "agentCode",
    styles: {
      header: {
        width: "150px",
      },
    },
  },
  {
    Header: "ชื่อสมาชิก",
    accessor: "name",
    styles: {
      header: {
        width: "220px",
      },
    },
  },
  {
    Header: "วันที่เริ่มงาน",
    accessor: "activatedAt",
    styles: {
      header: {
        width: "160px",
        textAlign: "center",
      },
      cell: {
        textAlign: "center",
      },
    },
    Cell: (cell) => {
      return cell.value ? dateFormatterFromPattern(cell.value) : "-";
    },
  },
  {
    Header: "วันที่เข้าทีม",
    accessor: "joinTeamAt",
    styles: {
      header: {
        width: "160px",
        textAlign: "center",
      },
      cell: {
        textAlign: "center",
      },
    },
    Cell: (cell) => {
      return cell.value ? dateFormatterFromPattern(cell.value) : "-";
    },
  },
  {
    Header: "วันที่สิ้นสุด",
    accessor: "exitTeamAt",
    styles: {
      header: {
        width: "160px",
        textAlign: "center",
      },
      cell: {
        textAlign: "center",
      },
    },
    Cell: (cell) => {
      return cell.value ? dateFormatterFromPattern(cell.value) : "-";
    },
  },
];

const DeleteTeam = authorize(
  ({ onDelete, disabled }) => {
    const [isModalOpened, , openModal, closeModal] = useToggle();
    return (
      <>
        <button
          className={`secondary ${styles.editBtn}`}
          onClick={openModal}
          disabled={disabled}
        >
          ลบทีม
        </button>
        <Modal
          contentLabel="Delete team."
          isOpen={isModalOpened}
          onSubmit={onDelete}
          onCancel={closeModal}
        >
          ต้องการลบทีมใช่หรือไม่
        </Modal>
      </>
    );
  },
  {
    roles: "team",
    params: { unauthorizedAction: UnauthorizedAction.DISABLED },
    actions: ["delete"],
  }
);

function TeamId(props) {
  const { data = {}, disabled, onDelete = () => {} } = props;

  if (data.id) {
    return (
      <Container>
        <h4 className="topic">ดูทีม</h4>
        <div className={containerStyles.breadcrumbWithButton}>
          <Breadcrumb
            links={[
              { label: "จัดการทีมผู้แนะนำ", link: "/team" },
              { label: data.name, current: true },
            ]}
          />
          <div>
            <DeleteTeam onDelete={onDelete} />
            <Link to={`/team/${data.id}/edit`}>
              <button className={styles.btn} disabled={disabled}>
                แก้ไขทีม
              </button>
            </Link>
          </div>
        </div>
        <section className={styles.displayValues}>
          <DisplayValue label="ชื่อทีม" value={data.name} />
          <DisplayValue label="พื้นที่" value={data.areaCode} />
          <DisplayValue
            label="สาขา"
            value={`(${data.branchCode}) - ${data.branchName}`}
          />
          <DisplayValue
            label="กลุ่มงานขาย"
            value={
              data.salesGroup === "mb" ? "ต่างจังหวัด (MB)" : "กรุงเทพ (EB)"
            }
          />
          <DisplayValue label="ทีมหลัก TQM" value={data.tqmTeam} />
          <DisplayValue
            label="หัวหน้าทีมผู้แนะนำ"
            value={data.affiliateLeaderName}
          />
        </section>
        <p className={styles.member}>
          สมาชิกทั้งหมด : {data.affiliateMembers?.length || "0"} คน
        </p>
        <Table data={data.affiliateMembers || []} columns={columns} />
      </Container>
    );
  }
  return null;
}

export default authorize(enhancer(TeamId), {
  roles: "team",
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
  actions: ["modify"],
});
