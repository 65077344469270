import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose, mapProps } from "recompose";
import withHooks from "lib/withHooks";

import { filterObjByArray } from "utils/helper";
import { getAgentOptions } from "store/agentSlice";
import useToggle from "hooks/useToggle";

const enhancer = compose(
  withHooks((props) => {
    const dispatch = useDispatch();
    const options = useSelector((state) => state.agent.options);
    const [isShow, , handleOpen, handleClose] = useToggle();

    useEffect(() => {
      dispatch(getAgentOptions());
    }, [dispatch]);

    return {
      options,
      isShow,
      handleOpen,
      handleClose,
    };
  }),

  mapProps((props) =>
    filterObjByArray(props, [
      "options",
      "id",
      "onSubmit",
      "initialValues",
      "sendEmail",
      "isLoading",
      "data",
      "updateAgentType",
      "errorRecruit",
      "handleChangeRecruit",
      "showModal",
      "handleCloseModal",
      "isShow",
      "handleOpen",
      "handleClose",
    ])
  )
);

export default enhancer;
