import { useEffect, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import Icon from "components/Icon";
import { Formik, Field as FormikField } from "formik";
import cx from "classnames";
import merge from "lodash/merge";
import isEqual from "lodash/isEqual";

import Modal from "components/Modal";
import Input from "components/Form/Element/Input";
import Gender from "components/Form/Element/Gender";
import Selector from "components/Form/Element/Selector";
import DatePicker from "components/Form/Element/DatePicker";
import DEFAULT_AVATAR from "assets/images/avatar_agent.png";
import Address from "components/Form/Element/Address";

import { dateFormatterFromPattern } from "utils/formatter";
import validation from "./AgentForm.validation";
import { DEFAULT_VALUES } from "./AgentForm.utils";
import enhancer from "./AgentForm.compose";
import styles from "./AgentForm.module.css";
import { getYearsOptions } from "../Element/DatePicker/DatePicker.utils";
import AffiliateForm from "./AffiliateForm";
import NonLifeBrokerForm from "./NonLifeBrokerForm";
import LifeBrokerForm from "./LifeBrokerForm";
import Archive from "pages/Agent/_id/Archive";
import Documents from "./Documents";

const HomeAddress = () => {
  return (
    <>
      <p style={{ marginBottom: 24 }}>ที่อยู่ตามทะเบียนบ้าน</p>
      <Address name="homeAddress" />
    </>
  );
};

const IdCardAddress = ({ hidden = false, onChange = () => {} }) => {
  return (
    <>
      <p style={{ marginBottom: 24 }}>ที่อยู่ตามบัตรประชาชน</p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <FormikField
          name="isSameHomeAddress"
          type="checkbox"
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <label style={{ marginLeft: "16px" }}>
          เหมือนที่อยู่ตามทะเบียนบ้าน
        </label>
      </div>
      {!hidden && <Address name="idCardAddress" />}
    </>
  );
};

const CurrentAddress = ({ hidden = false, onChange = () => {} }) => {
  return (
    <>
      <p style={{ marginBottom: 24 }}>ที่อยู่ในการส่งเอกสาร</p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <FormikField
          name="isSameIdCardAddress"
          type="checkbox"
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <label style={{ marginLeft: "16px" }}>
          เหมือนที่อยู่ตามบัตรประชาชน
        </label>
      </div>
      {!hidden && <Address name="currentAddress" />}
    </>
  );
};

function NewSendEmail({
  disabled,
  sendEmail = () => {},
  showModal,
  handleCloseModal,
}) {
  const onSubmit = () => {
    handleCloseModal();
    let values = { params: disabled ? "rejected" : "updated" };
    sendEmail(values);
  };
  const onCancel = () => {
    handleCloseModal();
    sendEmail({}, true);
  };

  const text = useMemo(() => {
    if (disabled) {
      return (
        <p>
          ต้องการส่งอีเมลแจ้ง <b>ไม่อนุมัติการสมัคร</b>
          <br />
          ไปยังผู้สมัครด้วยหรือไม่ ?
        </p>
      );
    }

    return (
      <p>
        ต้องการส่งอีเมลแจ้ง <b>แก้ไขเอกสาร</b>
        <br />
        ไปยังผู้สมัครด้วยหรือไม่ ?
      </p>
    );
  }, [disabled]);

  return (
    <Modal
      isOpen={showModal}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onClose={handleCloseModal}
      contentLabel="SendEmail Modal"
      submitText="ส่งอีเมล"
      cancelText="ไม่ส่งอีเมล"
    >
      {text}
    </Modal>
  );
}

const Form = ({
  id = "",
  options = [],
  sendEmail = () => {},
  updateAgentType = () => {},
  errorRecruit,
  handleChangeRecruit = () => {},
  showModal,
  handleCloseModal,
  formikProps,
  isShow = false,
  handleOpen,
  handleClose,
  initialValues,
}) => {
  const { values = {}, setFieldValue, handleSubmit } = formikProps;

  const disabled = useMemo(() => {
    return (
      values.agentStatus === "ปฏิเสธถาวร (Blacklist)" ||
      values.agentStatus === "ปฏิเสธชั่วคราว (1 ปี)"
    );
  }, [values.agentStatus]);

  useEffect(() => {
    if (
      isEqual(values.homeAddress, values.idCardAddress) &&
      !values.isSameHomeAddress
    ) {
      setFieldValue("isSameHomeAddress", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.homeAddress, values.idCardAddress]);

  useEffect(() => {
    if (values.isSameHomeAddress) {
      setFieldValue("idCardAddress", values.homeAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.isSameHomeAddress, values.homeAddress]);

  useEffect(() => {
    if (
      isEqual(values.idCardAddress, values.currentAddress) &&
      !values.isSameIdCardAddress
    ) {
      setFieldValue("isSameIdCardAddress", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.idCardAddress, values.currentAddress]);

  useEffect(() => {
    if (values.isSameIdCardAddress) {
      setFieldValue("currentAddress", values.idCardAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.isSameIdCardAddress, values.idCardAddress]);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <section className={styles.portfolio}>
        <div className={styles.editbtn}>
          <Link to={`/agents/${id}/history`}>
            <button className="secondary" type="button" disabled>
              ประวัติการแก้ไข
            </button>
          </Link>
          <button type="submit" style={{ marginLeft: 24 }}>
            บันทึก
          </button>
          <NewSendEmail
            sendEmail={sendEmail}
            disabled={disabled}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
          />
        </div>
        <img
          src={values.avatar || DEFAULT_AVATAR}
          alt="agent avatar"
          className={styles.logo}
        />
        <div className={styles.newInterview}>
          <div className={styles.rowInterview}>
            <div className={styles.columnInterview}>
              <Selector
                label="สถานะ"
                name="agentStatus"
                className={styles.agentStatus}
                options={
                  ["ปฏิเสธถาวร (Blacklist)", "ปฏิเสธชั่วคราว (1 ปี)"].includes(
                    initialValues?.agentStatus || ""
                  ) && options?.agentStatusOptions
                    ? options.agentStatusOptions
                    : options?.agentStatusOptions
                    ? options.agentStatusOptions.filter(
                        (agentStatus) =>
                          ![
                            "ปฏิเสธถาวร (Blacklist)",
                            "ปฏิเสธชั่วคราว (1 ปี)",
                          ].includes(agentStatus?.value)
                      )
                    : []
                }
                width="100%"
              />
            </div>
            <div className={cx(styles.columnInterview, styles.lastUpdatedBy)}>
              <small>ผู้อัปเดตล่าสุด : {values?.lastUpdatedBy || "-"}</small>
              <small>
                วันที่อัปเดต :{" "}
                {values?.updatedAt
                  ? dateFormatterFromPattern(values.updatedAt)
                  : "-"}
              </small>
            </div>
          </div>
        </div>
        <AffiliateForm
          values={values}
          setFieldValue={setFieldValue}
          options={options}
          errorRecruit={errorRecruit}
          handleChangeRecruit={handleChangeRecruit}
          updateAgentType={updateAgentType}
        />
        <LifeBrokerForm
          values={values}
          setFieldValue={setFieldValue}
          options={options}
          errorRecruit={errorRecruit}
          handleChangeRecruit={handleChangeRecruit}
          updateAgentType={updateAgentType}
        />
        <NonLifeBrokerForm values={values} />
        <div className={styles.personal}>
          <div className={styles.row}>
            <Selector
              label="คำนำหน้าชื่อ"
              name="prefixName"
              className={styles.prefixName}
              options={[
                { label: "นาย", value: "นาย" },
                { label: "นางสาว", value: "นางสาว" },
                { label: "นาง", value: "นาง" },
                { label: "ด.ช.", value: "ด.ช." },
                { label: "ด.ญ.", value: "ด.ญ." },
              ]}
              width="100%"
            />
            <Input
              label="ชื่อจริง"
              className={styles.firstName}
              name="firstName"
              placeholder="โปรดระบุ"
            />
            <Input
              label="นามสกุล"
              className={styles.lastName}
              name="lastName"
              placeholder="โปรดระบุ"
            />
          </div>
          <div className={styles.row}>
            <Gender name="gender" label="เพศ" className={styles.gender} />
            <DatePicker
              className={styles.datepicker}
              label="วันเกิด"
              name="birthdate"
              showSelector
              maxDate={new Date()}
              yearOptions={getYearsOptions(null, "desc", 50)}
            />
          </div>
          <div className={styles.row}>
            <Input
              label="เลขที่ประจำตัวประชาชน"
              className={styles.inputHalf}
              name="idCard"
              placeholder="โปรดระบุ"
            />
          </div>
          <div className={styles.row}>
            <Input
              label="เบอร์โทรศัพท์"
              className={styles.inputHalf}
              name="phoneNumber"
              placeholder="โปรดระบุ"
              disabled
              required
            />
            <Input
              label="อีเมล"
              className={styles.inputHalf}
              name="email"
              placeholder="โปรดระบุ"
              disabled
              required
            />
          </div>
          <HomeAddress />
          <IdCardAddress
            subname="isSameHomeAddress"
            title="ที่อยู่ตามบัตรประชาชน"
            subtitle="เหมือนที่อยู่ตามทะเบียนบ้าน"
            onChange={(e) => {
              setFieldValue("isSameHomeAddress", e);
            }}
            hidden={values.isSameHomeAddress}
          />
          <CurrentAddress
            subname="isSameIdCardAddress"
            title="ที่อยู่ในการส่งเอกสาร"
            subtitle="เหมือนที่อยู่ตามบัตรประชาชน"
            onChange={(e) => {
              setFieldValue("isSameIdCardAddress", e);
            }}
            hidden={values.isSameIdCardAddress}
          />
          <Selector
            label="บริษัทประกันชีวิตต้นสังกัด"
            name="insuranceCompanyName"
            className={styles.insuranceCompanyName}
            options={options?.insuranceCompanyNameOptions || []}
            width="100%"
          />
        </div>
      </section>
      <section className={styles.recruit}>
        <div className={styles.explain}>
          <div className={styles.explainLabel}>
            <label>ชี้แจงสำคัญ</label>
            <Icon name="info" className={styles.iconInfo} />
          </div>
          <Input
            className={styles.explainInput}
            name="explain"
            placeholder="โปรดระบุ"
          />
        </div>
      </section>
      {isShow ? (
        <Archive
          className={styles.editArchive}
          handleClose={handleClose}
          data={initialValues.archiveDocuments}
        />
      ) : (
        <Documents
          values={values}
          disabled={disabled}
          options={options}
          handleOpen={handleOpen}
        />
      )}
    </form>
  );
};

const AgentForm = (props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={merge({}, DEFAULT_VALUES, props.initialValues)}
      validationSchema={validation}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return <Form {...props} formikProps={formikProps} />;
      }}
    </Formik>
  );
};

export default enhancer(AgentForm);
