import { Formik } from "formik";
import cx from "classnames";
import merge from "lodash/merge";

import MemberTable from "./MemberTable";
import Input from "components/Form/Element/Input";
import Selector from "components/Form/Element/Selector";
import Modal from "components/Modal";

import enhancer from "./TeamForm.compose";
import validation from "./TeamForm.validation";
import { DEFAULT_VALUES } from "./TeamForm.utils";
import styles from "./TeamForm.module.css";

function TeamForm({
  id = "",
  options = {
    affiliateOptions: [],
    branchOptions: [],
    salesGroupOptions: [],
    tqmTeamOptions: [],
    affiliateLeaderOptions: [],
    affiliateSearchOptions: [],
  },
  className = "",
  initialValues = {},
  selectedRow = {},
  isModalOpened = false,
  onSubmit = () => {},
  onCloseModal = () => {},
  onDeleteMember = () => {},
  onSelectedMember = () => {},
  filterOptions = () => {},
  filterSaleOptions = () => {},
}) {
  return (
    <Formik
      initialValues={merge({}, DEFAULT_VALUES, initialValues)}
      validationSchema={validation}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;

        const addMember = () => {
          const foundMember = options.affiliateOptions.find(
            (mem) => mem.value === values.selectedMember
          );
          let affiliateMembers = values.affiliateMembers;
          let foundMemberFormat = {
            ...foundMember,
            id: foundMember.value,
            name: foundMember.label,
            joinTeamAt: new Date(),
          };
          delete foundMemberFormat.value;
          delete foundMemberFormat.label;
          setFieldValue("affiliateMembers", [
            ...affiliateMembers,
            foundMemberFormat,
          ]);
          setFieldValue("selectedMember", "");
        };

        return (
          <form
            id={id}
            onSubmit={handleSubmit}
            className={cx(styles.container, className)}
          >
            <div className={styles.fillContainer}>
              <Input
                label="ชื่อทีม"
                name="name"
                placeholder="กรุณากรอก"
                required
              />
              <Selector
                label="สาขา"
                name="branchCode"
                placeholder="กรุณาเลือก"
                options={options.branchOptions}
                width="100%"
                required
                isSearchable
              />
              <Selector
                label="กลุ่มงานขาย"
                name="salesGroup"
                placeholder="กรุณาเลือก"
                options={options.salesGroupOptions}
                width="100%"
                onChange={() => {
                  setFieldValue("tqmTeamId", "");
                  setFieldValue("affiliateLeaderId", "");
                }}
                isSearchable
              />
              <Selector
                label="ทีมหลัก TQM"
                name="tqmTeamId"
                placeholder="กรุณาเลือก"
                options={filterSaleOptions(
                  options.tqmTeamOptions,
                  values.salesGroup
                )}
                width="100%"
                isSearchable
              />
              <Selector
                label="หัวหน้าทีมผู้แนะนำ"
                name="affiliateLeaderId"
                placeholder="กรุณาเลือก"
                options={options.affiliateLeaderOptions}
                width="100%"
                required
                isSearchable
              />
              <Selector
                label="สมาชิก"
                name="selectedMember"
                placeholder="กรุณาเลือก"
                disabled={!values.affiliateLeaderId}
                options={filterOptions(
                  options.affiliateOptions,
                  values.affiliateLeaderId,
                  values.affiliateMembers
                )}
                width="100%"
                isSearchable
              />
            </div>
            <div className={styles.memberContainer}>
              <button
                type="button"
                disabled={!values.selectedMember}
                onClick={addMember}
                className={`secondary ${styles.addMemberBtn}`}
              >
                เพิ่ม
              </button>
              <p className={styles.member}>
                สมาชิกทั้งหมด : {values.affiliateMembers?.length || "-"} คน
              </p>
            </div>
            {values.affiliateMembers?.length > 0 && (
              <MemberTable
                data={values.affiliateMembers}
                onSelectedMember={onSelectedMember}
              />
            )}
            <Modal
              contentLabel="Delete member from team."
              isOpen={isModalOpened}
              onSubmit={onDeleteMember}
              onCancel={onCloseModal}
            >
              ต้องการลบสมาชิก
              <br />
              {selectedRow?.values?.name || "-"} ใช่หรือไม่ ?
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
}

export default enhancer(TeamForm);
